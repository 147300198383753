import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { IMenuButton } from "@ps-erp-angular/ps-ui";
import { Helper, SolicitacaoAlvaraService } from "@ps-erp-angular/shared";
import { NzModalService } from "ng-zorro-antd/modal";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { NzUploadFile } from "ng-zorro-antd/upload";


@Component({
  selector: 'solicitacao-alvara',
  templateUrl: './solicitacao-alvara.component.html',
  styleUrls: ['./solicitacao-alvara.component.scss'],
})
export class SolicitacaoAlvaraComponent implements OnInit {

  constructor(
    private formBuilder: FormBuilder,
    public solicitacaoService: SolicitacaoAlvaraService,
    public notification: NzNotificationService,
    private modalService: NzModalService
  ) {
    this.formDadosContribuinte = formBuilder.group({
      inscricaoMunicipal: null,
      nPessoa: null,
      nomeLogradouro: null,
      numero: null,
      nBairro: null,
      nCidade: null,
      uf: null,
      cep: null
    })

    this.formSolicitarAlvara = formBuilder.group({
      email: [null],
      telefone: [null],
      anexos: this.formBuilder.array([])})

    this.resetFabButtons();
  }

  tabIndex: 0 | 1 = 0;
  loadingPage = false;
  fabButtons: IMenuButton[];
  fieldSelected: any;
  fieldsSelected = [];
  searchInput;
  showComboTags = false;
  selectedValues = [];
  formDadosContribuinte: FormGroup = new FormGroup({});
  formSolicitarAlvara: FormGroup = new FormGroup({});
  dataContribuinte: any
  anexos: NzUploadFile[] = [];
  anexoErro = false;
  maxFileSize = 4194304;
  formParams;
  formData = new FormData();
  dataSolicitacao;
  ativaAlvara: boolean = false;

  panel = [
    {
      active: false,
      name: 'teste'
    }
  ]
  
  opcoesFiltro = [
    {
      value: 'cnpj',
      text: 'CNPJ Contribuinte',
      type: 'input',
      enabled: true,
    },
  ]
  

  ngOnInit() {

  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: this.tabIndex === 1 || this.tabIndex === 0,
        onClick: () => null,
      },
      {
        icon: 'send',
        tooltip: 'Enviar ou reenviar',
        color: 'green',
        condition: this.tabIndex === 1,
        onClick: () => null,
      },
      {
        icon: 'clock-circle',
        tooltip: 'programar',
        color: 'blue',
        condition: false,
        onClick: () => null,
      },
      {
        icon: 'save',
        tooltip: 'Enviar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: () => this.onOk(),
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: () => null,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: () => null,
      },
      // {
      //   icon: 'search',
      //   tooltip: 'Consultar',
      //   condition: this.tabIndex === 0,
      //   onClick: () => null,
      // },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        // color: 'blue',
        condition: true,
        onClick: () => null
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;
    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  getOptionsSelect() {
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  addFiltroPorCampo() {
    if (this.fieldSelected && this.searchInput) {
      this.fieldsSelected.push({
        props: this.fieldSelected.value,
        field: this.fieldSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });
  
      const filtro = this.opcoesFiltro.find(
        (item) => item.value === this.fieldSelected.value
      );
  
      filtro.enabled = false;
      this.showComboTags = true;
      this.fieldSelected = '';
      this.searchInput = '';
      this.selectedValues = [];
    }
    
  }

  removeFiltroPorField(index) {
    const filtro = this.opcoesFiltro.find(
      (item) => item.value === this.fieldsSelected[index].props
    );

    filtro.enabled = true;

    this.fieldsSelected.splice(index, 1);
    if (this.fieldsSelected.length === 0) {
      this.showComboTags = false;
    }
  }

  async getContribuinte() {

    try {
      const params = Helper.convertSelectedFieldsToURLParams(
        this.fieldsSelected
      );

      this.loadingPage = true;
      this.dataContribuinte = await this.solicitacaoService.getContribuinte(params)
    

      if(this.dataContribuinte !== null) {
        this.formDadosContribuinte.patchValue({
          inscricaoMunicipal: this.dataContribuinte.pessoa.inscMunicipal,
          nPessoa: this.dataContribuinte.pessoa.nPessoa,
          nomeLogradouro: this.dataContribuinte.pessoa.endereco,
          numero: this.dataContribuinte.pessoa.numero,
          nBairro: this.dataContribuinte.pessoa.nBairro,
          nCidade: this.dataContribuinte.pessoa.nCidade,
          uf: this.dataContribuinte.pessoa.uf,
          cep: this.dataContribuinte.pessoa.cep
        })

        this.ativaAlvara = true
        // this.ativarFormAlvara = true;
      }
    } catch(error) {
        this.notification.error('Solicitação de Alvara', error?.message || error);
    } finally {
      this.loadingPage = false;
    }
  }

  removeFile = (file: NzUploadFile): boolean => {
    const index = this.anexos.findIndex((anexo) => anexo.uid === file.uid);
    this.anexos.splice(index, 1);
    return true;
  };

  upload = (file: NzUploadFile): boolean => {
    this.anexoErro = false;

    if (file.size > this.maxFileSize) {
      this.anexoErro = true;
      this.notification.error(
        'Solicitação Alvará',
        'Selecione um documento menor que 4mb!'
      );

      return false;
    }

    const entidadeAnexosForm: FormArray = this.formSolicitarAlvara.get(
      'anexos'
    ) as FormArray;

    entidadeAnexosForm.push(
      this.formBuilder.group({
        file,
      })
    );

    this.anexos = this.anexos.concat(file);
    return false;
  };

  onOk() {

    if(!this.formSolicitarAlvara.valid) {    
      this.notification.error(
        'Solicitação Alvará',
        'Preencha corretamente o formulário'
      );

      return;
    }

    this.modalService.confirm({
      nzTitle: 'Confirmar Solicitação Alvará',
      nzContent: 'Você confirma o envio da solicitação de alvará?',
      nzOkText: 'OK',
      nzCancelText: 'CANCELAR',
      nzOnOk: () => this.solicitacaoAlvara()
    });

  }

  async solicitacaoAlvara() {
    try {

      this.formParams = {
        email: this.formSolicitarAlvara.get('email').value,
        numeroTelefone: this.formSolicitarAlvara.get('telefone').value,
        cnpj: this.dataContribuinte.cnpj
      }

      const filteredParams = {}

      for (const key in this.formParams) {
        const value = this.formParams[key];
          if (value !== null && value.length > 0 ) {
            filteredParams[key] = value;
            this.formData.append(key, value)
          }
      }

      const anexosFileArray = this.formSolicitarAlvara.get('anexos') as FormArray;
        for (let i = 0; i < anexosFileArray.length; i++) {
        const fileControl = anexosFileArray.at(i) as FormGroup;
        const file: File = fileControl.get('file').value;

        this.formData.append('docsSolicitacao', file, file.name);
      }

      this.loadingPage = true;
      this.dataSolicitacao = await this.solicitacaoService.postSolicitacaoAlvara(filteredParams, this.formData)

      this.modalSucessProcesso()

      this.notification.success(
        'Solicitação de Alvará',
        'Enviada com sucesso!'
      );
      
    } catch (error) {
      this.notification.error(
        'Solicitação de Alvará',
        error?.message || error
      );
    } finally {

      this.formParams = null;
      this.formData = new FormData();
      this.anexos = [];
      const anexosFileArray = this.formSolicitarAlvara.get('anexos') as FormArray;
      while (anexosFileArray.length !== 0) {
        anexosFileArray.removeAt(0);
      }

      this.formDadosContribuinte.reset();
      this.formSolicitarAlvara.reset();
      this.ativaAlvara = false;
      this.loadingPage = false;
    }

  }

  modalSucessProcesso() {
    this.modalService.success({
      nzTitle: 'Segue o Código do Processo:',
      nzContent: this.dataSolicitacao.id,
    });
  }

}