import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class SolicitacaoITBIValidatorsService {
  constructor() {}

  cpfValidator(): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
      const cpfValue = control.value;

      // Verificar se o valor é nulo ou vazio
      if (!cpfValue) {
        return null; // Não há erro se o campo está vazio
      }

      // Verificar o tamanho do CNPJ
      if (cpfValue.length > 0) {
        const baseTamanhoCNPJ = 11;

        if (cpfValue.length < baseTamanhoCNPJ) {
          return { cnpjInvalid: true };
        }
      }

      return null;
    };
  }

  preencherFieldValidators(fields: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const filledFields = fields.filter(field => control.get(field)?.value !== null && control.get(field)?.value !== '');
  
      if (filledFields.length === 0) {
        return { atLeastOneFieldRequired: true };
      }
  
      return null;
    };
  }

  // Outras validações personalizadas podem ser adicionadas aqui
}
