import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { speedDialFabAnimations } from './speed-dial-fab.animations';

export type Icon =
  | 'eye'
  | 'null'
  | 'percentage'
  | 'clock-circle'
  | 'send'
  | 'more'
  | 'plus'
  | 'save'
  | 'edit'
  | 'delete'
  | 'stop'
  | 'printer'
  | 'select'
  | 'select'
  | 'search'
  | 'form'
  | 'download'
  | 'close-circle'
  | 'rollback'
  | 'clear'
  | 'upload'
  | 'diff'
  | 'loading'
  | 'reload';
export type Tooltip =
  | 'Opções'
  | 'Novo Cadastro'
  | 'Editar'
  | 'Deletar'
  | 'Salvar'
  | 'Cancelar'
  | 'Imprimir'
  | 'Selecionar'
  | 'Consultar'
  | 'Assinar'
  | 'Download'
  | 'Discordar'
  | 'Enviar'
  | 'Sincronizar Prefeitura Digital'
  | 'Resetar'
  | 'Desfazer Desacordo'
  | 'Atualizar'
  | 'Ratificar Documento';

export type btnColor =
  | 'blue'
  | 'green'
  | 'yellow'
  | 'orange'
  | 'red'
  | 'bordo'
  | 'purple'
  | 'secondary'
  | 'black';

export enum colors {
  // "blue" = 'rgba(34, 123, 253,0.25)',
  'blue' = '#E7EFFA',
  'green' = '#E9F5E4',
  'yellow' = '#FBF5E2',
  'orange' = '#FAEFE3',
  'red' = '#F2C5C2',
  'bordo' = '#ffcccc',
  'purple' = '#e8bdfc',
  'black' = '#F1F2F3',
}

export enum fontColors {
  'blue' = '#3F87F5',
  'green' = '#52C41A',
  'yellow' = '#FFC107',
  'orange' = '#FA8C16',
  'red' = '#E1594D',
  'purple' = '#630094',
  'bordo' = '#962424',
  'black' = '#53535F',
}
export interface IMenuButton {
  icon: Icon;
  tooltip?: Tooltip | string;
  percent?: number | string;
  divider?: boolean;
  condition?: boolean;
  color?: btnColor;
  onClick?: () => void;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-fab',
  templateUrl: './speed-dial-fab.component.html',
  styleUrls: ['./speed-dial-fab.component.scss'],
  animations: speedDialFabAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpeedDialFabComponent implements OnChanges {
  @Input() actions: IMenuButton[];
  public options = [];
  public innerWidth: any;
  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }
  pushOptions() {
    if (this.options.length != 0) {
      this.options = [];
      return;
    }
    const actFab = this.actions.map((btn) => {
      return {
        ...btn,
        color: colors[btn.color],
        fontColor: fontColors[btn.color],
      };
    });
    this.options = actFab;
  }

  parseFloat(number: number = 0) {
    if (this.actions[0].icon === 'null') {
      return `${parseFloat(`${number}`).toLocaleString('pt-BR', {
        currency: 'BRL',
        minimumFractionDigits: 2,
      })}%`;
    }
  }

  ngOnChanges(changes) {
    if (
      changes.actions.currentValue.length &&
      changes.actions.currentValue !== changes.actions.previousValue
    ) {
      this.actions = changes.actions.currentValue;
    }
  }

  isActive() {
    return (
      this.actions.length > 0 && this.actions.some((action) => action.condition)
    );
  }
}
