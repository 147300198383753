import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper, SolicitacaoITBI } from '@ps-erp-angular/shared';
import { SolicitacaoITBIValidatorsService } from 'libs/shared/src/lib/services/solicitacao-itbi-validators.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
  selector: 'ps-soliciticao-itbi',
  templateUrl: './soliciticao-itbi.component.html',
  styleUrls: ['./soliciticao-itbi.component.scss'],
})
export class SoliciticaoITBIComponent implements OnInit {
  public tabIndex: 0 | 1 = 0;
  public fabButtons: IMenuButton[];
  public loadingPage: boolean;
  public fromSolicitarITBI: FormGroup = new FormGroup({});
  public formDadosImovel: FormGroup = new FormGroup({});

  constructor(
    private formBuilder: FormBuilder,
    public notification: NzNotificationService,
    public solicitacaoITBI: SolicitacaoITBI,
    public validatorsService: SolicitacaoITBIValidatorsService,
    public modalService: NzModalService
  ) {
    this.formDadosImovel = formBuilder.group({
      codigoImovel: null,
      inscricaoImovel: null,
      localizacaoCartografica: null,
      nPessoa: null,
      nomeLogradouro: null,
      nBairro: null,
      nCidade: null,
      uf: null,
      cep: null
    })

    //TODO: resolver problema de validação
    this.fromSolicitarITBI = formBuilder.group({
      cpf: [null, validatorsService.cpfValidator],
      rg: [null, [Validators.pattern(/^\d{7,9}$/)]],
      anexos: this.formBuilder.array([])
    }, {validator: validatorsService.preencherFieldValidators(['cpf', 'rg']) })

    this.resetFabButtons();
  }

  fieldSelected: any;
  fieldsSelected = [];
  searchInput;
  showComboTags = false;
  selectedValues = [];
  ativarFormITBI = false;

  dataImovel: any;

  maxFileSize = 4194304;
  anexoErro = false;
  anexos: NzUploadFile[] = [];
  formParams;
  formData = new FormData();
  dataSolicitacao;

  opcoesFiltro = [
    {
      value: 'localizacaoCartografica',
      text: 'Localização Cartográfica',
      type: 'input',
      enabled: true,
    },
    {
      value: 'codigoImovel',
      text: 'Código do Imóvel',
      type: 'input',
      enabled: true,
    }
  ]



  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: this.tabIndex === 1 || this.tabIndex === 0,
        onClick: () => null,
      },
      {
        icon: 'send',
        tooltip: 'Enviar ou reenviar',
        color: 'green',
        condition: this.tabIndex === 1,
        onClick: () => null,
      },
      {
        icon: 'clock-circle',
        tooltip: 'programar',
        color: 'blue',
        condition: false,
        onClick: () => null,
      },
      {
        icon: 'save',
        tooltip: 'Enviar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: () => this.onOk(),
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: () => null,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: () => null,
      },
      // {
      //   icon: 'search',
      //   tooltip: 'Consultar',
      //   condition: this.tabIndex === 0,
      //   onClick: () => null,
      // },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        // color: 'blue',
        condition: true,
        onClick: () => this.reloadPage(),
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }
  async onOk() {

    if (!this.fromSolicitarITBI.valid) {
      const preencherFieldError = Object.keys(this.fromSolicitarITBI.controls)
      .map(controlName => this.fromSolicitarITBI.get(controlName))
      .some(control => control?.errors?.preencherFieldValidators);

      if(!preencherFieldError) {
        this.notification.error(
          'Formulário',
          'Preencha pelo menos um dos campos (CPF ou RG)'
        );
        return;
      }

      this.notification.error(
        'Formulário',
        'Preencha corretamente o formulário'
      );
      return;
    }
   
    this.modalService.confirm({
      nzTitle: 'Confirmar Solicitação ITBI',
      nzContent: 'Você confirma o envio da solicitação de ITBI?',
      nzOkText: 'OK',
      nzCancelText: 'CANCELAR',
      nzOnOk: () => this.solicitarITBI()
    });

  
  }

  reloadPage() {}

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;
    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  addFiltroPorCampo() {
    if (this.fieldSelected && this.searchInput) {
      this.fieldsSelected.push({
        props: this.fieldSelected.value,
        field: this.fieldSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });
  
      const filtro = this.opcoesFiltro.find(
        (item) => item.value === this.fieldSelected.value
      );
  
      filtro.enabled = false;
      this.showComboTags = true;
      this.fieldSelected = '';
      this.searchInput = '';
      this.selectedValues = [];
    }
    
  }

  getOptionsSelect() {
  }

  async getImovel() {

    try {
      const params = Helper.convertSelectedFieldsToURLParams(
        this.fieldsSelected
      );
      this.loadingPage = true;
      this.dataImovel = await this.solicitacaoITBI.getOneImovel(params)
      
      if(this.dataImovel !== null) {
        this.formDadosImovel.patchValue({
          codigoImovel: this.dataImovel.codigoImovel,
          inscricaoImovel: this.dataImovel.inscricaoImovel,
          localizacaoCartografica: this.dataImovel.localizacaoCartografica,
          nPessoa: this.dataImovel.proprietario.nPessoa,
          nomeLogradouro: this.dataImovel.logradouroBairro.logradouro.nomeLogradouro,
          nBairro: this.dataImovel.logradouroBairro.bairro.nBairro,
          nCidade: this.dataImovel.logradouroBairro.bairro.cidade.nCidade,
          uf: this.dataImovel.logradouroBairro.bairro.cidade.uf,
          cep: this.dataImovel.logradouroBairro.logradouro.cep
        })

        this.ativarFormITBI = true;
      }
    } catch(error) {
        this.notification.error('Solicitação de ITBI', error?.message || error);
    } finally {
      this.loadingPage = false;
    }

  }

  async solicitarITBI() {
    try {

      this.formParams = {
        cpf: this.fromSolicitarITBI.get('cpf').value,
        rg: this.fromSolicitarITBI.get('rg').value,
        localizacaoCartografica: this.dataImovel.localizacaoCartografica,
        codigoImovel: this.dataImovel.codigoImovel
      };

      const filteredParams = {};

      for (const key in this.formParams) {
          const value = this.formParams[key];
          if (value !== null && value.length > 0 ) {
          filteredParams[key] = value;
        }
      }
      
      const anexosFileArray = this.fromSolicitarITBI.get('anexos') as FormArray;
      for (let i = 0; i < anexosFileArray.length; i++) {
      const fileControl = anexosFileArray.at(i) as FormGroup;
      const file: File = fileControl.get('file').value;

      this.formData.append('docsSolicitacao', file, file.name);
      }


      this.loadingPage = true;
      this.dataSolicitacao = await this.solicitacaoITBI.postSolicitarITBI(filteredParams, this.formData)

      this.modalSucessProcesso()

      this.notification.success(
        'Solicitção Enviada',
        'Solicitação de ITBI enviada com sucesso!'
      );
      
    } catch(error) {
      this.notification.error(
        'Solicitação de ITBI',
        error?.message || error
      );
    } finally {
      this.formParams = null;
      this.formData = new FormData();
      this.anexos = [];
      const anexosFileArray = this.fromSolicitarITBI.get('anexos') as FormArray;
      while (anexosFileArray.length !== 0) {
        anexosFileArray.removeAt(0);
      }

      this.formDadosImovel.reset();
      this.fromSolicitarITBI.reset();
      this.loadingPage = false;
    }
  }

  removeFiltroPorField(index) {
    const filtro = this.opcoesFiltro.find(
      (item) => item.value === this.fieldsSelected[index].props
    );

    filtro.enabled = true;

    this.fieldsSelected.splice(index, 1);
    if (this.fieldsSelected.length === 0) {
      this.showComboTags = false;
    }
  }

  removeFile = (file: NzUploadFile): boolean => {
    const index = this.anexos.findIndex((anexo) => anexo.uid === file.uid);
    this.anexos.splice(index, 1);
    return true;
  };

  upload = (file: NzUploadFile): boolean => {
    this.anexoErro = false;

    if (file.size > this.maxFileSize) {
      this.anexoErro = true;
      this.notification.error(
        'Documento',
        'Selecione um documento menor que 4mb!'
      );

      return false;
    }

    const entidadeAnexosForm: FormArray = this.fromSolicitarITBI.get(
      'anexos'
    ) as FormArray;

    entidadeAnexosForm.push(
      this.formBuilder.group({
        file,
      })
    );

    this.anexos = this.anexos.concat(file);
    return false;
  };

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  modalSucessProcesso() {
    this.modalService.success({
      nzTitle: 'Segue o Código do Processo:',
      nzContent: this.dataSolicitacao.id,
    });
  }

  ngOnInit(): void {
    
    // this.buildFormConsultarImovel();
    // this.buildFormDadosImovel();
  }
}
