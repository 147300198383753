import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable()
export class SolicitacaoCancelamentoNFSEValidatorsService {
  constructor() {}

  cnpjValidator(): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
      const cnpjValue = control.value;

      // Verificar se o valor é nulo ou vazio
      if (!cnpjValue) {
        return null; // Não há erro se o campo está vazio
      }

      // Verificar o tamanho do CNPJ
      if (cnpjValue.length > 0) {
        const baseTamanhoCNPJ = 14;

        if (cnpjValue.length < baseTamanhoCNPJ) {
          return { cnpjInvalid: true };
        }
      }

      return null;
    };
  }

  periodoValidator(): (control: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl) => {
      const periodoValue = control.value;

      if (!periodoValue) {
        return null;
      }

      if (periodoValue.length > 0) {
        const baseTamanhoPeriodo = 6;
        if (periodoValue.length < baseTamanhoPeriodo) {
          return { periodoValidator: true };
        }
      }

      return null;
    };
  }

  // Outras validações personalizadas podem ser adicionadas aqui
}
