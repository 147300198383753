export enum EventsActionsDoc {
  'UsuarioCriador' = 'UsuarioCriador',
  'Visualizar' = 'Visualizar',
  'Edicao' = 'Edicao',
  'DownloadOriginal' = 'DownloadOriginal',
  'DownloadCancelado' = 'DownloadCancelado',
  'DownloadDesacordo' = 'DownloadDesacordo',
  'DownloadAssinado' = 'DownloadAssinado',
  'Cancelar' = 'Cancelar',
  'Discordar' = 'Discordar',
  'Desfazer' = 'Desfeito',
  'Assinar' = 'Assinar',
  'VisualizarDesacordado' = 'VisualizarDesacordado',
  'VisualizarCancelado' = 'VisualizarCancelado',
  'VisualizarAssinado' = 'VisualizarAssinado',
  'VisualizarAnexo' = 'VisualizarAnexo',
  'VisualizarAnexoAssinado' = 'VisualizarAnexoAssinado',
  'VisualizarDocPai' = 'VisualizarDocPai',
  'DownloadAnexo' = 'DownloadAnexo',
  'Editar' = 'Editar',
  'AssinarAnexo' = 'AssinarAnexo',
  'DiscordarAnexo' = 'DiscordarAnexo',
  'DesfeitoAnexo' = 'DesfeitoAnexo',
  'CancelarAnexo' = 'CancelarAnexo',
  'RatificarDocumento' = 'RatificarDocumento',
  'VisualizarRatificado' = 'VisualizarRatificado',
}
