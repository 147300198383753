<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="'Solicitação de Cancelamento NFSE'" [tabIndex]="tabIndex" [isAntd]="true"
    (changeTabIndex)="changeTabIndex($event)">

    <div body>
      <nz-spin [nzSpinning]="false">
        <form nz-form [formGroup]="formB" nzLayout="vertical">
          <div nz-row class="m-t-20" style="justify-content: space-between;">
            <div class="p-r-20" nz-col nzSpan="6" nzSm="6" nzXs="6">
              <nz-form-item>
                <nz-form-label nzRequired>Número da Nota</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="numeroNota" placeholder="Número Nota">
                </nz-form-control>
              </nz-form-item>
            </div>

            <div class="p-r-20" nz-col nzSpan="6" nzSm="6" nzXs="6">
              <nz-form-item>
                <nz-form-label nzRequired>Período</nz-form-label>
                <nz-form-control>
                  <input mask="00/0000" nz-input formControlName="periodo" placeholder="Exemplo: 10/2023">
                </nz-form-control>
                <div *ngIf="formB.get('periodo').hasError('periodoValidator')">
                  <p class="error-message-style">Preencha corretamente o campo</p>
                </div>
              </nz-form-item>
            </div>
          </div>

          <div nz-row style="justify-content: space-between;">
            <div class="p-r-20" nz-col nzSpan="10" nzSm="10" nzXs="10">
              <nz-form-item>
                <nz-form-label>CNPJ Prestador</nz-form-label>
                <nz-form-control>
                  <input mask="00.000.000/0000-00" nz-input formControlName="cnpjPrestador" placeholder="Exemplo: 00.000.000/00000-00">
                </nz-form-control>
                <div *ngIf="formB.get('cnpjPrestador').invalid">
                  <p class="error-message-style">Preencha corretamente o campo</p>
                </div>
              </nz-form-item>
            </div>

            <div class="p-r-20" nz-col nzSpan="10" nzSm="10" nzXs="10">
              <nz-form-item>
                <nz-form-label>CNPJ Tomador</nz-form-label>
                <nz-form-control>
                  <input mask="00.000.000/0000-00" nz-input formControlName="cpfCnpjTomador" placeholder="Exemplo: 00.000.000/00000-00">
                </nz-form-control>
                <div *ngIf="formB.get('cpfCnpjTomador').invalid">
                  <p class="error-message-style">Preencha corretamente o campo</p>
                </div>
              </nz-form-item>
            </div>

          </div>

          <div class="p-r-15">
            <nz-form-item>
              <nz-form-label>Motivo do Cancelamento</nz-form-label>
              <nz-form-control>
                <textarea nz-input formControlName="motivo" [nzAutosize]="{ minRows: 3, maxRows: 999 }"
                      placeholder="Texto do Motivo" nzAutosize nzMentionTrigger></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
          
          <nz-form-item>
            <nz-form-label>Documentos necessários cancelamento:</nz-form-label>
            <nz-upload [(nzFileList)]="anexos" [nzRemove]="removeFile" [nzBeforeUpload]="upload" nz-tooltip
                    nzTooltipTitle="Envie documentos necessários pro cancelamento" nzTooltipPlacement="left">
                    <button nz-button><i nz-icon
                        nzType="upload"></i>Selecionar Anexos</button>
                        <div *ngIf= "anexoErro">
                          <p class="error-message-style">*selecione documento menor que 4mb!</p>
                        </div>
            </nz-upload>
          </nz-form-item>
            

        </form>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
  <nz-modal [(nzVisible)]="cancelarNFSEModal" nzTitle="NFSE para Cancelamento" (nzOnCancel)="exitCancelarNFSEModal()" [nzMaskClosable]="false">
    <ng-container *nzModalContent>
      <div>
        <nz-form-label>Nome do Tomador</nz-form-label>
        <input nz-input [(ngModel)]="dataNFSEModal.data.nomeTomador" readonly />
      </div>
      <div>
        <nz-form-label>Nome do Prestador</nz-form-label>
        <input nz-input [(ngModel)]="dataNFSEModal.data.contribuinte.pessoa.nPessoa" readonly />
      </div>

      <nz-row style="justify-content: space-between;">
        <nz-col nzSpan="6">
          <nz-form-item class="label-modal">
            <nz-form-label >Número da Nota</nz-form-label>
            <nz-form-control>
              <input nz-input [(ngModel)]="dataNFSEModal.data.codigoInterno" readonly />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="6">
          <nz-form-item class="label-modal">
            <nz-form-label >Período</nz-form-label>
            <nz-form-control>
              <input nz-input [ngModel]="formatFields('periodo',dataNFSEModal.data.periodo)" readonly />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="6">
          <nz-form-item class="label-modal">
            <nz-form-label >Valor</nz-form-label>
            <nz-form-control>
              <input nz-input [ngModel]="formatFields('valor',dataNFSEModal.data.valorNota)" readonly />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
      <nz-row style="justify-content: space-between;">
        <nz-col nzSpan="8">
          <nz-form-item class="label-modal">
            <nz-form-label >Data de Emissão</nz-form-label>
            <nz-form-control>
              <input nz-input [ngModel]="formatFields('data',dataNFSEModal.data.dataEmissao)" readonly />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
        <nz-col nzSpan="8">
          <nz-form-item class="label-modal">
            <nz-form-label >Data de Vencimento</nz-form-label>
            <nz-form-control>
              <input nz-input [ngModel]="formatFields('data',dataNFSEModal.data.dataVencimento)" readonly />
            </nz-form-control>
          </nz-form-item>
        </nz-col>
      </nz-row>
    </ng-container>
    <ng-container *nzModalFooter>
      <span class="text-confirm-nfse-cancel-modal">Deseja fazer a solicitação de cancelamento?</span>
      <button nz-button class="buttonsModal" nzType="default" (click)="exitCancelarNFSEModal()">Não</button>
      <button nz-button class="buttonsModal" nzType="primary" (click)="okCancelarNFSEModal()">Sim</button>
    </ng-container>
  </nz-modal>
  
</nz-spin>
