import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { ProcessoComponent } from './processo/processo.component';
import { SolicitacaoAlvaraComponent } from './solicitacao-alvara/solicitacao-alvara.component';
import { SolicitacaoCancelamentoNFSEComponent } from './solicitacao-cancelamento-nfse/solicitacao-cancelamento-nfse.component';
import { SoliciticaoITBIComponent } from './soliciticao-itbi/soliciticao-itbi.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'session',
    pathMatch: 'full',
  },
  {
    path: 'session',
    component: LayoutComponent,
    //canActivate: [AuthGuardContribuinte],
    children: [
      {
        path: 'solicitacao-cancelamento-nfse',
        component: SolicitacaoCancelamentoNFSEComponent,
      },
      {
        path: 'consultar-processos',
        component: ProcessoComponent,
      },
      {
        path: 'solicitacao-itbi',
        component: SoliciticaoITBIComponent,
      },
      {
        path: 'solicitacao-alvara',
        component: SolicitacaoAlvaraComponent
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
