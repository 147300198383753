import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DetalheProcessoInterface } from '../interfaces/detalhe.processo-interface';
import { ProcessoInterface } from '../interfaces/processo-interface';
import { SearchfieldsInterface } from '../interfaces/searchfields-interface';
import { ServiceResponse } from './../utils';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';

@Injectable({ providedIn: 'root' })
export class ProcessoService extends AbstractService{
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService
    ) {
    super(
      'processo',
      abstractHttp,
      abstractAuthService,
      abstractNotification
    );
  }

  async getProcessos(params?): Promise<ProcessoInterface[]> {
    params = new URLSearchParams(params).toString();

    const url = `${this.getServer('processo')}?${params}`;

    const response = (await this.http
      .get(url)
      .toPromise()) as ServiceResponse;

    return response.data as ProcessoInterface[];
  }

  async getSearchFields(): Promise<SearchfieldsInterface> {
    const url = `${this.getServer('processo')}/search-fields`;

    const response = (await this.http
      .get(url)
      .toPromise()) as ServiceResponse;

    return response.data as SearchfieldsInterface;
  }

  async getDetalhe(params): Promise<DetalheProcessoInterface[]> {
    params = new URLSearchParams(params).toString();
    const url = `${this.getServer('processo')}/get-detalhe?${params}`

    const response = (await this.http
      .get(url)
      .toPromise()) as ServiceResponse;

      return response.data as DetalheProcessoInterface[]
  }

  async getAnexoView(uuid): Promise<void> {
    const localInfoString = localStorage.getItem('local-info')
    const localInfo  = JSON.parse(localInfoString)
    const url = `${this.getServer('file')}/processo/${uuid}?action=view`
    return new Promise<void>((resolve, reject) => {
      this.http.get(url, {
        headers: new HttpHeaders({
          'x-authorization' : `${localInfo.cnpjInst}`
        }),
        responseType: 'arraybuffer',
      }).subscribe(
        (response) => {
          const blob = new Blob([response], { type: 'application/pdf' });
          const blobUrl = window.URL.createObjectURL(blob);
          const test = window.open(blobUrl);

          setTimeout(() => {
            window.URL.revokeObjectURL(blobUrl);
            resolve();
          }, 1000);
        },
        (error) => {
          console.error(error.message)
          reject(error)
        },  
      );
    });
  }

  async getAnexoBaixar(uuid): Promise<void> {
    const localInfoString = localStorage.getItem('local-info');
    const localInfo = JSON.parse(localInfoString);
    const url = `${this.getServer('file')}/processo/${uuid}?action=download`
    return new Promise<void>((resolve, reject) => {
      this.http.get(url, {
        headers: new HttpHeaders({
          //'x-authorization': `${localInfo.cnpjInst}`,
          'x-authorization': `09073628000191`,
        }), 
        responseType: 'arraybuffer',
      }).subscribe((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob)
        link.download = `comprovante_processo_ITBI_${uuid}.pdf`
        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)

        window.URL.revokeObjectURL(link.href)

        resolve()
      },
      (error) => {
        console.error('Corpo da resposta: ', error?.error)
        reject(error)
      })
    })
  }
}
