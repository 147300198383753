import { Component } from '@angular/core';
import {
  AuthService,
  DocumentsUploadService,
  MenuItem,
  MenuService,
} from '@ps-erp-angular/shared';

@Component({
  selector: 'ps-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'portal-do-contribuinte';
  formLoading = false;
  private labelTip!: string;
  private formulario = [];
  private readonly menu: MenuItem[] = [
    {
      path: 'nota-fiscal-eletronica',
      title: 'Nota Fiscal Eletronica',
      entidade: 'nota fiscal eletronica',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'file-protect',
      submenu: [
        {
          path: '/session/solicitacao-cancelamento-nfse',
          title: `Solicitação de Cancelamento de NFSE`,
          entidade: 'solicitacao de cancelamento de nfse',
          iconType: 'nzIcon',
          icon: 'file-exclamation',
          iconTheme: 'outline',
          submenu: [],
        },
      ],
    },
    {
      path: 'imobiliario',
      title: 'Imobiliario',
      entidade: 'imobiliario',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'home',
      submenu: [
        {
          path: '/session/solicitacao-itbi',
          title: `solicitacao ITBI`,
          entidade: 'solicitacao',
          iconType: 'nzIcon',
          icon: 'file-exclamation',
          iconTheme: 'outline',
          submenu: [],
        },
      ],
    },
    {
      path: 'processo',
      title: 'Processos',
      entidade: 'processo',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'container',
      submenu: [
        {
          path: '/session/consultar-processos',
          title: `Meus Processos`,
          entidade: 'consultar processos',
          iconType: 'nzIcon',
          icon: 'unordered-list',
          iconTheme: 'outline',
          submenu: [],
        },
      ],
    },
    {
      path: 'mercatil',
      title: 'Mercantil',
      entidade: 'mercantil',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'shop',
      submenu: [
        {
          path: '/session/solicitacao-alvara',
          title: `Solicitação de Alvará`,
          entidade: 'solicitacao alvara',
          iconType: 'nzIcon',
          icon: 'file-text',
          iconTheme: 'outline',
          submenu: [],
        },
      ],
    },
  ];

  constructor(
    private menuService: MenuService,
    private authService: AuthService,
    private service: DocumentsUploadService
  ) {
    this.menuService.menuItems.next(this.menu);
    this.formulario = this.authService.getFormularios();

    this.service.formLoading.subscribe(async (data) => {
      this.formLoading = data;
    });

    this.service.labelTip.subscribe(async (msg) => {
      this.labelTip = msg;
    });
  }

  private mountMenu() {
    let data;

    this.formulario.forEach((form) => {
      data = this.menu.map((menu) => {
        menu.submenu.map((submenu) => {
          if (submenu.entidade === form.entidade) {
            return submenu;
          }
        });

        return menu;
      });
    });

    this.menuService.menuItems.next(data);
  }
}
