import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { SolicitacaoCancelamentoNFSEService } from 'libs/shared/src/lib/services/solicitacao-cancelamento-nfse.service';
import { ServiceResponse } from 'libs/shared/src/lib/utils';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardContribuinte implements CanActivate {
  constructor(
    private solicitacaoCancelamentoService: SolicitacaoCancelamentoNFSEService,
    private notification: NzNotificationService,
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {

  
    if (localStorage.getItem('authToken')) {
      const dataAtual = new Date(Date.now())

      const dataStorage = localStorage.getItem('validade')
      const dataValidade = moment(dataStorage, 'DD/MM/yyyy').toDate()
      dataValidade.setHours(0, 0, 0, 0)
      dataAtual.setHours(0, 0, 0 ,0)

      // console.log('dataValidade: ', dataValidade)
      // console.log('dataAtual: ', dataAtual)
      if(dataAtual > dataValidade) {
        this.notification.warning('Sessão expirado!', 'Acesse o login novamente.')
        localStorage.clear()
        return false;
      }
      return true;
    }

    localStorage.setItem(
      'local-info', 
      JSON.stringify({ ...route.queryParams})
      );

    const token = route.queryParams['cookie'];

    if (!token) {
      return false;
    }

    return this.solicitacaoCancelamentoService
      .verificaToken(token)
      .then((response: ServiceResponse) => {
        localStorage.setItem('authToken', token);
        localStorage.setItem('validade', moment(new Date(Date.now())).format('DD/MM/yyyy'))
        localStorage.setItem('uf', response.data.instituicao['uf']);
        localStorage.setItem('ug', response.data.instituicao['nInstituicao']);
        localStorage.setItem('brasaoImg', response.data['brasaoUrl']);
        return response.data['token'] === token;
      })
      .catch((error) => {
        this.notification.error(
          'Acesso Negado!',
          'Erro ao tentar acessar o login.'
        );
        return false;
      });
  }
}
