<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form [title]="'Solicitação de ITBI'" [tabIndex]="tabIndex" [isAntd]="true"
    (changeTabIndex)="changeTabIndex($event)">


    <div body>
      <h4 class="m-t-15">Informações básicas</h4>
      <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
      <div nz-row nzGutter="16"></div>

      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <div nz-row class="d-flex">
            <div nz-col nzSpan="24" nzXs="24">
              <div class="p-l-10 p-b-15 p-r-10" nz-col nzSpan="24">
                <div nz-row class="m-l-20">
                  <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo" [(ngModel)]="fieldSelected" (ngModelChange)="getOptionsSelect()">
                    <nz-option *ngFor="let interval of opcoesFiltro" [nzValue]="interval" [nzLabel]="interval?.text">
                    </nz-option>
                  </nz-select>

                  <nz-input-group *ngIf="fieldSelected" class="m-b-10 m-l-20" nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24" [nzPrefix]="prefixTemplate">
                    <input type="text" class="text-disabled" nz-input placeholder="Informe um valor" [(ngModel)]="searchInput" [mask]="getMaskReturned" />
                  </nz-input-group>

                  <ng-template #prefixTemplate>
                    <i nz-icon nzType="search" class="opacity-05"></i>
                  </ng-template>
                  <!-- </div> -->
      
                  <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                    <button nz-button [disabled]="
                        !searchInput
                      " (click)="addFiltroPorCampo()" nzType="primary">
                      <i nz-icon nzType="plus"></i>
                    </button>
                  </div>
                </div>

                <div nz-row class="m-t-10 m-l-20">
      
                  <div *ngIf="fieldsSelected.length !=0" nz-col nzSpan="12" nzXl="12" nzLg="12" nzXs="24">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                      <div class="align-self-center">
                        <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                      removeFiltroPorField(i)
                        " class="filters" *ngFor="let fieldsValuesTag of fieldsSelected; let i = index;">
                          {{ fieldsValuesTag.text }}
                        </nz-tag>
                      </div>
                    </nz-input-group>
      
                  </div>
                  <div nz-col [class]="'m-t-10 p-l-20'" [nzOffset]="fieldsSelected.length !=0?8:20" nzSpan="3" nzXl="3"
                    nzLg="3" nzXs="24">
                    <button nzSize="small" nz-button nzType="primary" (click)="getImovel()">
                      <i nz-icon nzType="filter" nzTheme="outline"></i>Buscar
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
        <!-- <div nz-row nzGutter="16">
          <div nz-col nzSpan="8" nzSm="6" nzXs="24">
            <nz-form-item>
              <nz-form-label>Código do Imóvel</nz-form-label>
              <nz-form-control>
                <input nz-input formControlName="codImovel" placeholder="Código do Imóvel">
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="8" nzSm="6" nzXs="24">
            <nz-form-item>
              <nz-form-label>Localização Cartográfica</nz-form-label>
              <nz-form-control>
                <input nz-input formControlName="localizacaoCartografica" placeholder="Localização Cartográfica">
              </nz-form-control>
            </nz-form-item>
          </div>
        </div> -->

      <nz-collapse nzAccordion>
        <nz-collapse-panel [nzHeader]="'Informações do Imóvel'" [nzActive]="true">
          <form nz-form [formGroup]="formDadosImovel" nzLayout="vertical">
            <nz-divider class="m-t-0" nzOrientation="left" [nzText]="basico">
              <ng-template #basico>
                <span nz-icon nzType="home" nzTheme="outline"></span>{{''}}
                <span>Dados do Imóvel</span>
              </ng-template>
            </nz-divider>

            <div nz-row nzGutter="16">
              <div nz-col nzSpan="8" nzSm="6" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Código do Imóvel</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="codigoImovel" readonly placeholder="Código do Imóvel">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="8" nzSm="6" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Inscrição do Imóvel</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="inscricaoImovel" readonly placeholder="Inscrição do Imóvel">
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="8" nzSm="6" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Localização Cartográfica</nz-form-label>
                  <nz-form-control>
                    <input nz-input readonly formControlName="localizacaoCartografica" placeholder="Localização Cartográfica">
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <nz-divider class="m-t-0" nzOrientation="left" [nzText]="proprietario">
              <ng-template #proprietario>
                <span nz-icon nzType="user" nzTheme="outline"></span>{{''}}
                <span>Dados do Proprietário</span>
              </ng-template>
            </nz-divider>

            <div nz-row nzGutter="16" >
              <div nz-col nzSpan="8" nzSm="8" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Nome</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="nPessoa" readonly placeholder="Nome">
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <nz-divider class="m-t-0" nzOrientation="left" [nzText]="endereco">
              <ng-template #endereco>
                <span nz-icon nzType="environment" nzTheme="outline"></span>{{''}}
                <span>Endereço</span>
              </ng-template>
            </nz-divider>

            <div nz-row nzGutter="16">
              <div nz-col nzSpan="8" nzSm="12" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Logradouro</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="nomeLogradouro" readonly placeholder="Logradouro">
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8" nzSm="6" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Bairro</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="nBairro" readonly placeholder="Bairro">
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

            <div nz-row nzGutter="16">
              <div nz-col nzSpan="8" nzSm="6" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Cidade</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="nCidade" readonly placeholder="Cidade">
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8" nzSm="2" nzXs="24">
                <nz-form-item>
                  <nz-form-label>UF</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="uf" readonly placeholder="UF">
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8" nzSm="6" nzXs="24">
                <nz-form-item>
                  <nz-form-label>CEP</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="cep" readonly placeholder="CEP">
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>

          </form>
        </nz-collapse-panel>
      </nz-collapse>
      
      <div>
        <h4 class="m-t-15">Formulário de Solicitação de ITBI</h4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
        <div nz-row nzGutter="16"></div>

        <form nz-form [formGroup]="fromSolicitarITBI" nzLayout="vertical">
          <div nz-row nzGutter="16">
            <div nz-col nzSpan="8" nzSm="6" nzXs="24">
              <nz-form-item>
                <nz-form-label>CPF - Requerente</nz-form-label>
                <nz-form-control>
                  <input nz-input mask="000.000.000-00" formControlName="cpf" placeholder="CPF">
                </nz-form-control>
                <div *ngIf="fromSolicitarITBI.get('cpf').invalid">
                  <p class="error-message-style">O CPF deve ter 11 digitos.</p>
                </div>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="8" nzSm="6" nzXs="24">
              <nz-form-item>
                <nz-form-label>RG - Requerente</nz-form-label>
                <nz-form-control>
                  <input nz-input maxlength="9" formControlName="rg" placeholder="RG">
                </nz-form-control>
                <div *ngIf="fromSolicitarITBI.get('rg').hasError('pattern')">
                  <p class="error-message-style">O RG deve ter 7 ou 9 digitos.</p>
                </div>
              </nz-form-item>
            </div>
          </div>

          <div nz-row nzGutter="16">
            <div nz-col>
              <nz-form-item>
                
                <nz-upload [(nzFileList)]="anexos" [nzRemove]="removeFile" [nzBeforeUpload]="upload" nz-tooltip
                        nzTooltipTitle="Envie documentos necessários para solicitar o ITBI" nzTooltipPlacement="left">
                        <button nz-button><i nz-icon
                            nzType="upload"></i>Selecionar Anexos</button>
                            <div *ngIf= "anexoErro">
                              <p class="error-message-style">*selecione documento menor que 4mb!</p>
                            </div>
                </nz-upload>
                <nz-form-label><p class="error-message-style">Obrigatório comprovante de residência. <br> Ausência do mesmo implica em indeferimento da solicitação.</p> 
                </nz-form-label>
              </nz-form-item>
            </div>
          </div>

        </form>
      </div>
        

      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>