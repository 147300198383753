import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  MenuItem,
  PsTabSessionService,
  ThemeService,
} from '@ps-erp-angular/shared';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ps-sidebar',
  templateUrl: './ps-sidebar.component.html',
  styleUrls: ['./ps-sidebar.component.scss'],
})
export class PsSidebarComponent implements OnInit {
  @Input() menuItems: MenuItem[];
  @Input() version: string;
  search: any;
  isFolded: boolean;
  isExpand: boolean;
  isSideNavDark: boolean;
  @Output() toggleFolder: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private themeService: ThemeService,
    private tabSessionService: PsTabSessionService
  ) {}

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );

    const initialPage = this.menuItems.find((page) => page.initialPage);

    if (initialPage) {
      this.tabSessionService.addTab({
        name: initialPage.title,
        url: initialPage.path,
      });
    }
  }

  addTab(item: MenuItem): void {
    this.tabSessionService.addTab({ name: item.title, url: item.path });
    // this.toggleExpand();
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.toggleFolder.emit();
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }
}
