import { Component, Input, OnInit } from "@angular/core";
import { ProcessoService, SolicitacaoITBI, StatusProcesso, ThemeService } from "@ps-erp-angular/shared";
import { DetalheProcessoInterface, FilesUploadedInterface } from "libs/shared/src/lib/interfaces/detalhe.processo-interface";
import * as moment from "moment";
import { NzNotificationService } from "ng-zorro-antd/notification";

@Component({
  selector: 'modal-processo-detalhe',
  templateUrl: './modalProcessoDetalhe.component.html',
  styleUrls: ['./modalProcessoDetalhe.component.scss'],
})
export class ModalProcessoDetalhe implements OnInit {

  loadingPage: boolean =  false;
  loadingEvents: false;
  anexosDetalhe: DetalheProcessoInterface[] = [];
  arquivosTitle: string = 'Anexos';
  loadingAnexos: boolean = false;
  indeterminate: boolean = true;
  listDetalhe: DetalheProcessoInterface[] = []
  listExpand: boolean = false;

  constructor(
    private readonly solicitacaoITBI: SolicitacaoITBI,
    private readonly processoService: ProcessoService,
    private readonly themeService: ThemeService,
    private readonly notification: NzNotificationService
    ){}

  @Input()
  public processo: any
  ngOnInit(): void {
    this.getDetalheProcesso()
  }

  listOfColumn = [
    {
      text: 'Descrição',
      sortOrder: null,
      value: 'descricao',
      compare: (a: DetalheProcessoInterface, b: DetalheProcessoInterface) =>
        a.descricao.localeCompare(b.descricao),
      priority: false,
    },
    {
      text: 'Status',
      sortOrder: null,
      value: 'evento',
      compare: (a: DetalheProcessoInterface, b: DetalheProcessoInterface) =>
        a.evento.localeCompare(b.evento),
      priority: false,
    },
    {
      text: 'Nome',
      sortOrder: null,
      value: 'arquivoNome',
      compare: (a: FilesUploadedInterface, b: FilesUploadedInterface) =>
        a.arquivoNome.localeCompare(b.arquivoNome),
      priority: false,
    },
    {
      text: 'Criado',
      sortOrder: null,
      value: 'createdAt',
      compare: (a: DetalheProcessoInterface, b: DetalheProcessoInterface) =>
        a.createdAt.localeCompare(b.createdAt),
      priority: false,
    },
    {
      text: 'Atualizado',
      sortOrder: null,
      value: 'updatedAt',
      compare: (a: DetalheProcessoInterface, b: DetalheProcessoInterface) =>
        a.updatedAt.localeCompare(b.updatedAt),
      priority: false,
    }
  ]

  checkList = {
    descricao: true,
    evento: true,
    arquivoNome: true,
    createdAt: true,
    updatedAt: true,
  };

  getColumns(column) {
    return this.listOfColumn.find((columnObj)=> columnObj.value == column)
  }

  public baixarProcesso(){
   
  }
  public visualizarArquivo(){
    this.solicitacaoITBI.viewComprovante(this.processo.id);
  }

  public baixarArquivo() {
    this.solicitacaoITBI.baixarComprovante(this.processo.id)
  }

  getDataCreatedProcesso() {
    return moment(this.processo?.createdAt)
      .utcOffset(0, true)
      .format('DD/MM/YYYY - HH:mm:ss');
  }

  public getStatus(status: number): string {
    switch (status) {
      case StatusProcesso.PENDENTE:
        return 'PENDENTE';
      case StatusProcesso.ARQUIVO_DEFERIDO:
        return 'DEFERIDO';
      case StatusProcesso.ARQUIVO_INDEFERIDO:
        return 'INDEFERIDO';
      case StatusProcesso.AGUARDANDO_PAGAMENTO:
        return 'AGUARDANDO_PAGAMENTO'
    }
  }

  getSystemProcesso() {
    return this.processo?.system;
  }

  async getDetalheProcesso() {

    this.loadingAnexos = true;

    const param = { 'idProcesso': this.processo.id }

    this.anexosDetalhe = await this.processoService.getDetalhe(param)
    this.listDetalhe = this.anexosDetalhe;

    this.listDetalhe.forEach(detalhe => {
     detalhe.expand = false
    })
    
    this.loadingAnexos = false;
    return this.anexosDetalhe
  }

  getDetalhe() {
    return this.listDetalhe
  }

  getWidthContent() {
    return window.innerWidth;
  }

  decodeString(text) {
    const textConvert = decodeURIComponent(escape(text))
    return textConvert
  }

  async getBaixarAnexo(uuid) {
    try {
      this.loadingPage = true
      await this.processoService.getAnexoBaixar(uuid)
    } catch (error) {
      this.notification.error('Processo', error?.message || error)
    } finally {
      this.loadingPage = false
    }
  }

  async getViewAnexo(uuid) {
    try {
      this.loadingPage = true
      await this.processoService.getAnexoView(uuid)
    } catch (error) {
      this.notification.error('Processo', error?.message || error)
    } finally {
      this.loadingPage = false
    }
  }
  

  //TODO: terminar implementação pra cores dos status
  // getColorTheme(item) {
  //   const colors = this.themeService.get().colors
  //   colors[ColorStatusDoc[item]]
  // }
  

}