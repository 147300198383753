export const environment = {
  production: false,
  ambiente: 'HOMOLOGACAO',
  url: (service) => `https://ps-${service}.dev.publicservice.com.br/api`,
  panelUrl: () =>
    `https://ps-painel-administrativo-app.dev.publicservice.com.br`,
  apiOld: (service) => `https://ps-${service}-api.dev.publicservice.com.br`,
  apiAssinatura: (path) =>
    `https://ps-assinatura-documentos-api.dev.publicservice.com.br/${path}`,
  apiUrl: (service) => `https://ps-${service}-api.dev.publicservice.com.br`,
  apiTools: (service) => `https://ps-${service}-api.api.publicservice.com.br`,
  appUrl: (service) => `https://ps-${service}-app.dev.publicservice.com.br`,
  domain: 'dev.publicservice.com.br',
};
