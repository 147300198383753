<nz-spin [nzSpinning]="loadingPage" nzSize="large">
      <div style="text-align: end">
        <p style="text-align: end">Atualizado em: {{ getDateNow }}</p>
      </div>
    <div class="row is-not-mobile">
      <div class="col-md-24 col-xl-12 p-b-15">
        <nz-collapse nzGhost>
          <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active">
            <div nz-row class="d-flex">
              <div nz-col nzSpan="24" nzXs="24">
                <div class="p-l-10 p-b-15 p-r-10" nz-col nzSpan="24">
                  <div nz-row class="m-l-20">
                    <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo" [(ngModel)]="fieldSelected"
                      (ngModelChange)="getOptionsSelect(fieldSelected)">
                      <nz-option *ngFor="let interval of opcoesFiltro" [nzValue]="interval" [nzLabel]="interval?.text">
                      </nz-option>
                    </nz-select>
        
                    <!-- <div class="m-b-10 m-l-20" nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24"> -->
                    <nz-input-group *ngIf="fieldSelected && fieldSelected.type === 'input'" class="m-b-10 m-l-20" nz-col
                      nzSpan="8" nzXl="8" nzLg="12" nzXs="24" [nzPrefix]="prefixTemplate">
                      <input type="text" class="text-disabled" nz-input placeholder="Informe um valor" [(ngModel)]="searchInput"
                        [mask]="getMaskReturned" />
                    </nz-input-group>
        
                    <!-- <nz-select *ngIf="fieldSelected && fieldSelected.type === 'select'" class="m-b-10 m-l-20" nz-col nzSpan="8"
                      nzXl="8" nzLg="12" nzXs="24" nzPlaceHolder="Selecione o campo" [(ngModel)]="searchInput">
                      <nz-option *ngFor="let result of selectedValues" [nzValue]="result" [nzLabel]="result?.text">
                      </nz-option>
                    </nz-select> -->
        
                    <div *ngIf="fieldSelected && fieldSelected.type === 'range-picker'" nzSpan="8" nzXl="8" nzLg="12" nzXs="24"
                      class="m-b-10 m-l-20">
                      <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                    </div>
        
                    <div *ngIf="fieldSelected && fieldSelected.type === 'date-picker'" nzSpan="8" nzXl="12" nzLg="12" nzXs="24"
                      class="m-b-10 m-l-20">
                      <nz-date-picker nzShowTime nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-date-picker>
                    </div>

                    <!-- <div *ngIf="fieldSelected && fieldSelected.type === 'option'">
                      <nz-select style="width: 150px" nzPlaceHolder="Status">
                        <nz-option *ngFor="let interval of opcoesStatus" [nzValue]="interval" [nzLabel]="interval?.text">
                        </nz-option>
                      </nz-select>
                    </div> -->
                    
                    <nz-input-group *ngIf="fieldSelected && fieldSelected.type === 'statusOption'" class="m-b-10 m-l-20" nz-col 
                    nzSpan="8" nzXl="2" nzLg="12" nzXs="24">
                      <nz-select style="width: 150px" nzPlaceHolder="Status" [(ngModel)]="searchInput">
                        <nz-option *ngFor="let interval of opcoesStatus" [nzValue]="interval" [nzLabel]="interval?.text">
                        </nz-option>
                      </nz-select>
                    </nz-input-group>
        
                    <div nz-row *ngIf="fieldSelected && fieldSelected.type === 'boolean'" class="m-b-10 m-l-20">
                      <div nz-col nzSpan="24">
                        <nz-radio-group class="style-radio" [(ngModel)]="searchInput" nzName="radiogroup">
                          <label nz-radio [nzValue]="'Sim'">Sim</label>
                          <label nz-radio [nzValue]="'Não'">Não</label>
                        </nz-radio-group>
                      </div>
                    </div>
        
                    <ng-template #prefixTemplate>
                      <i nz-icon nzType="search" class="opacity-05"></i>
                    </ng-template>
                    <!-- </div> -->
        
                    <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                      <button nz-button [disabled]="
                          !searchInput
                        " (click)="addFiltroPorCampo()" nzType="primary">
                        <i nz-icon nzType="plus"></i>
                      </button>
                    </div>
        
                  </div>
        
                  <div nz-row class="m-t-10 m-l-20">
        
                    <div *ngIf="fieldsSelected.length !=0" nz-col nzSpan="12" nzXl="12" nzLg="12" nzXs="24">
                      <nz-input-group [nzPrefix]="prefixTemplate">
                        <div class="align-self-center">
                          <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                        removeFiltroPorField(i)
                          " class="filters" *ngFor="let fieldsValuesTag of fieldsSelected; let i = index;">
                            {{ fieldsValuesTag.text }}
                          </nz-tag>
                        </div>
                      </nz-input-group>
        
                    </div>
                    <div nz-col [class]="'m-t-10 p-l-20'" [nzOffset]="fieldsSelected.length !=0?8:20" nzSpan="3" nzXl="3"
                      nzLg="3" nzXs="24">
                      <button nzSize="small" nz-button nzType="primary" (click)="getProcessos()">
                        <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
    </div>
    <ps-fab [actions]="fabButtons">
    </ps-fab>
    <div>
      <nz-table #dataProcessos [nzData]="listProcessos" [nzLoading]="loadingTable" class="m-b-20" nzSize="small" [nzPageSize]="10" [nzShowPagination]="true"
      [nzScroll]="{ y: '420px' }" style="overflow-y: overlay">
        <thead>
          <tr>
            <th style="background: #edf1fd" nzWidth="30px" [nzLeft]="true"></th>
            <th nzWidth="70px" style="background: #edf1fd" [nzLeft]="true"></th>

            <ng-container>
              <th nzWidth="200px" [nzSortFn]="getColumns('codigo').compare" [nzSortPriority]="getColumns('codigo').priority" [nzSortOrder]="getColumns('codigo').sortOrder" *ngIf="checkList[getColumns('codigo').value]">
                {{ getColumns('codigo').text }}
              </th>
            </ng-container>

            <ng-container>
              <th nzWidth="200px" [nzSortFn]="getColumns('dtCriacao').compare" [nzSortPriority]="getColumns('dtCriacao').priority" [nzSortOrder]="getColumns('dtCriacao').sortOrder" *ngIf="checkList[getColumns('dtCriacao').value]">
                {{ getColumns('dtCriacao').text }}
              </th>
            </ng-container>

            <ng-container>
              <th nzWidth="200px"  [nzSortFn]="getColumns('dtAlteracao').compare" [nzSortPriority]="getColumns('dtAlteracao').priority" [nzSortOrder]="getColumns('dtAlteracao').sortOrder" *ngIf="checkList[getColumns('dtAlteracao').value]">
                {{ getColumns('dtAlteracao').text }}
              </th>
            </ng-container>

            <ng-container>
              <th nzWidth="200px"  [nzSortFn]="getColumns('id').compare" [nzSortPriority]="getColumns('id').priority" [nzSortOrder]="getColumns('id').sortOrder" *ngIf="checkList[getColumns('id').value]">
                {{ getColumns('id').text }}
              </th>
            </ng-container>

            <ng-container>
              <th nzWidth="200px" [nzSortFn]="getColumns('status').compare" [nzSortPriority]="getColumns('status').priority" [nzSortOrder]="getColumns('status').sortOrder" *ngIf="checkList[getColumns('status').value]">
                {{ getColumns('status').text }}
              </th>
            </ng-container>

            <ng-container>
              <th nzWidth="200px" style="background: #edf1fd" [nzRight]="getColumns('comprovante').floatRigth" [nzSortFn]="getColumns('comprovante').compare" [nzSortPriority]="getColumns('comprovante').priority" [nzSortOrder]="getColumns('comprovante').sortOrder" *ngIf="checkList[getColumns('comprovante').value]">
                {{ getColumns('comprovante').text }}
              </th>
            </ng-container>
            

            <th nzWidth="40px" style="background: #edf1fd" [nzRight]="getWindow() > 1024">
              <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click" [nzDropdownMenu]="colunas" >
                <i nz-icon nzType="setting"></i>
              </button>
            </th>

          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="dataProcessos.data" let-indexArr="index">
            <tr (dblclick)="navigateDetalhe(item)">
              <td [nzLeft]=" getWindow() > 1024" [(nzExpand)]="item.expand" (click)="getDetalheProcesso(item.id)">
              </td>
              <td></td>
              <td>
                <ng-container *ngIf="item?.idProcessoPersonalizado">
                  {{ item?.exercicio + '/' +  item?.idProcessoPersonalizado }} 
                </ng-container>
              </td>
              <td>
                {{ item.createdAt | date: 'dd/MM/yyyy' }}
              </td>
              <td>
                {{ item.updatedAt | date: 'dd/MM/yyyy' }}
              </td>
              <td>
                {{ item.id }}
              </td>
              <!-- <td class="tdStatus">
                <p [ngClass]="getStatus(processo.status)">{{ getStatus(processo.status) }}</p>
              </td> -->
              <td>
                <nz-tag [ngClass]="getStatus(item.status)">
                  {{ getStatus(item.status) }}
                </nz-tag>
              </td>
              <td colspan="2" [nzRight]="true">
                <ng-container *ngIf="validaFileTipoProcesso(item.idTipoProcesso)">
                  <span class="icon-clickable" (click)="getComprovante(item.id)">
                    <i nz-icon nzType="file-pdf" style="font-size: 35px" twoToneColor='#ff0000' theme="twotone"></i>
                  </span>
                </ng-container>
              </td>
  
            </tr>
  
            <tr [nzExpand]="item?.expand">
              <nz-spin *ngIf="item?.expand" [nzSpinning]="loadingPage" [nzSize]="'middle'">
                <nz-table #detalhesProcesso [nzData]="detalheProcesso" nzSize="middle"
                  [nzShowPagination]="false">
                  <thead>
                    <tr>
                      <th>Evento</th>
                      <th>Criado</th>
                      <th>Atualizado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of detalhesProcesso.data">
                      <td>
                        <nz-tag ngClass="{{ data?.evento }}">
                          {{ data?.evento }}
                        </nz-tag>
                      </td>

                      <td *ngIf="data?.createdAt">
                        {{ data?.createdAt }}
                      </td>

                      <td *ngIf="data?.updatedAt">
                        {{ data?.updatedAt }}
                      </td>
                      
                      <!-- <td *ngIf="item?.objectData?.files?.filesUploaded[0]?.arquivoUuid" class="cursor-pointer" [nzRight]="getWidthContent() > 1024">
                        <div class="d-flex">
                          <div (click)="getViewAnexo(item?.objectData?.files?.filesUploaded[0]?.arquivoUuid)" class="font-size-25 p-l-20 p-r-20" nz-tooltip
                            nzTooltipTitle="Clique para visualizar o documento" nzTooltipPlacement="left">
                            <i nz-icon [nzType]="'file-pdf'" theme="twotone" [twoToneColor]="red"></i>
                          </div>
                        </div>
                      </td>
  
                      <td *ngIf="item?.objectData?.files?.filesUploaded[0]?.arquivoUuid" (click)="getBaixarAnexo(item?.objectData?.files?.filesUploaded[0]?.arquivoUuid)" class="cursor-pointer" [nzRight]="getWidthContent() > 1024">
                        <div class="d-flex">
                          <div class="cursor-pointer"
                            class="font-size-25 p-l-20 p-r-20" nz-tooltip
                            nzTooltipTitle="Clique para baixar o documento" nzTooltipPlacement="left">
                            <i nz-icon [nzType]="'download'"></i>
                          </div>
                        </div>
                      </td> -->
                      
                    </tr>
                  </tbody>
                </nz-table>
              </nz-spin>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
    </div>
</nz-spin>

<nz-dropdown-menu #colunas="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngFor="let column of listOfColumn">
      <label nz-checkbox [(ngModel)]="checkList[column.value]">{{ column.text }}</label>
    </li>
    <li nz-menu-item class="text-center">
      <button nz-button nzType="text" nzSize="small" (click)="resetarListColumns()">Resetar</button>
    </li>
  </ul>
</nz-dropdown-menu>