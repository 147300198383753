<perfect-scrollbar class="side-nav is-not-mobile" sideNav>
    <ul class="ant-menu ant-menu-root ant-menu-inline side-nav-menu" [ngClass]="{
            'ant-menu-inline-collapsed': isFolded,
            'ant-menu-dark' : isSideNavDark
        }">
        <li [ngClass]="item.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
            *ngFor="let item of menuItems | searchRecursive:search:'title':'submenu'"
            [routerLinkActive]="item.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
            [routerLinkActiveOptions]="{exact: true}">
            <a href="javascript:void(0);" [title]="item.title" class="ant-menu-submenu-title"
                *ngIf="item.submenu.length > 0">
                <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [theme]="item.iconTheme"></i>
                <i *ngIf="item.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[item.iconTheme , item.icon]"></i>
                <span>{{item.title}}</span>
                <i class="ant-menu-submenu-arrow"></i>
            </a>
            <a [routerLink]="item.path" [title]="item.title" *ngIf="item.submenu.length === 0" (click)="addTab(item)">
                <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon" [theme]="item.iconTheme"></i>
                <i *ngIf="item.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[item.iconTheme , item.icon]"></i>
                <span>{{item.title}}</span>
            </a>

            <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="item.submenu.length > 0">
                <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
                    *ngFor="let subItem of item.submenu" [title]="subItem.title"
                    [routerLinkActive]="subItem.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
                    [routerLinkActiveOptions]="{exact: true}" nz-tooltip [nzTooltipTitle]="subItem.title"
                    nzTooltipPlacement="right">
                    <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50"
                        *ngIf="subItem.submenu.length > 0">
                        <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon"
                            [theme]="subItem.iconTheme"></i>
                        <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                            [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                        <span>{{subItem.title}}</span>
                        <i class="ant-menu-submenu-arrow"></i>
                    </a>
                    <a [routerLink]="subItem.path" class="p-l-10" *ngIf="subItem.submenu.length === 0"
                        (click)="addTab(subItem)">
                        <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon"
                            [theme]="subItem.iconTheme"></i>
                        <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                            [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                        <span>{{subItem.title}}</span>
                    </a>

                    <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="subItem.submenu.length > 0">
                        <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
                            *ngFor="let subItem of subItem.submenu" routerLinkActive="ant-menu-item-selected"
                            [title]="subItem.title" [routerLinkActiveOptions]="{exact: true}">
                            <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50"
                                *ngIf="subItem.submenu.length > 0">
                                <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon"
                                    [theme]="subItem.iconTheme"></i>
                                <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                                    [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                                <span>{{subItem.title}}</span>
                                <i class="ant-menu-submenu-arrow"></i>
                            </a>
                            <a [routerLink]="subItem.path" class="p-l-50" *ngIf="subItem.submenu.length === 0"
                                (click)="addTab(subItem)">
                                <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon"
                                    [theme]="subItem.iconTheme"></i>
                                <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                                    [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                                <span>{{subItem.title}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>

    <div class="menu-collapse" [ngClass]="isFolded ? 'collapsed' : 'not-collapsed'" (click)="toggleFold()">
        <a class="social-icons">
            <i nz-icon [nzType]="isFolded? 'right':'left'" theme="outline"></i>
        </a>
    </div>
</perfect-scrollbar>

<div class="is-mobile">
    <nz-drawer [nzWidth]="280" [nzClosable]="true" [nzVisible]="isFolded" nzPlacement="left" nzTitle="Menu de navegação"
        (nzOnClose)="toggleFold()">
        <div *nzDrawerContent>
            <perfect-scrollbar class="side-nav" sideNav>
                <ul class="ant-menu ant-menu-root ant-menu-inline side-nav-menu" [ngClass]="{
                        'ant-menu-dark' : isSideNavDark
                    }">
                    <li [ngClass]="item.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
                        *ngFor="let item of menuItems | searchRecursive:search:'title':'submenu'"
                        [routerLinkActive]="item.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
                        [routerLinkActiveOptions]="{exact: true}">
                        <a href="javascript:void(0);" [title]="item.title" class="ant-menu-submenu-title"
                            *ngIf="item.submenu.length > 0">
                            <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon"
                                [theme]="item.iconTheme"></i>
                            <i *ngIf="item.iconType == 'fontawesome'" class="m-r-10"
                                [ngClass]="[item.iconTheme , item.icon]"></i>
                            <span>{{item.title}}</span>
                            <i class="ant-menu-submenu-arrow"></i>
                        </a>
                        <a [routerLink]="item.path" [title]="item.title" *ngIf="item.submenu.length === 0"
                            (click)="addTab(item); toggleExpand()">
                            <i *ngIf="item.iconType == 'nzIcon'" nz-icon [nzType]="item.icon"
                                [theme]="item.iconTheme"></i>
                            <i *ngIf="item.iconType == 'fontawesome'" class="m-r-10"
                                [ngClass]="[item.iconTheme , item.icon]"></i>
                            <span>{{item.title}}</span>
                        </a>

                        <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="item.submenu.length > 0">
                            <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
                                *ngFor="let subItem of item.submenu" [title]="subItem.title"
                                [routerLinkActive]="subItem.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
                                [routerLinkActiveOptions]="{exact: true}" nz-tooltip [nzTooltipTitle]="null"
                                nzTooltipPlacement="right">
                                <!--[nzTooltipTitle]="subItem.title" alterado para [nzTooltipTitle]="null"-->
                                <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50"
                                    *ngIf="subItem.submenu.length > 0">
                                    <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon"
                                        [theme]="subItem.iconTheme"></i>
                                    <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                                        [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                                    <span>{{subItem.title}}</span>
                                    <i class="ant-menu-submenu-arrow"></i>
                                </a>
                                <a [routerLink]="subItem.path" class="p-l-10" *ngIf="subItem.submenu.length === 0"
                                    (click)="addTab(subItem); toggleExpand()">
                                    <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon"
                                        [theme]="subItem.iconTheme"></i>
                                    <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                                        [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                                    <span>{{subItem.title}}</span>
                                </a>

                                <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu"
                                    *ngIf="subItem.submenu.length > 0">
                                    <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
                                        *ngFor="let subItem of subItem.submenu"
                                        routerLinkActive="ant-menu-item-selected" [title]="subItem.title"
                                        [routerLinkActiveOptions]="{exact: true}">
                                        <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50"
                                            *ngIf="subItem.submenu.length > 0">
                                            <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon"
                                                [theme]="subItem.iconTheme"></i>
                                            <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                                                [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                                            <span>{{subItem.title}}</span>
                                            <i class="ant-menu-submenu-arrow"></i>
                                        </a>
                                        <a [routerLink]="subItem.path" class="p-l-50"
                                            *ngIf="subItem.submenu.length === 0"
                                            (click)="addTab(subItem); toggleExpand()">
                                            <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [nzType]="subItem.icon"
                                                [theme]="subItem.iconTheme"></i>
                                            <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                                                [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                                            <span>{{subItem.title}}</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </perfect-scrollbar>
        </div>
    </nz-drawer>
</div>