<nz-spin [nzSpinning]="loadingPage" [nzSize]="'large'">
  <div class="align-items-center p-v-10 shadow-default is-not-banner-mobile" nz-row style="background: #edf1fd">
    <div nz-col nzSpan="8">
      <div class="media align-items-center">
        <div *ngIf="processo" class="p-l-5">
          <h5 class="m-b-0">Data Processo:</h5>
          <h4 class="m-b-0">
            <b>{{ getDataCreatedProcesso() }}</b>
          </h4>
        </div>
      </div>
    </div>

    <div nz-col nzSpan="14" nzOffset="2">
      <div nz-row>
        <div class="d-flex p-t-5" nz-col nzSpan="8" style="justify-content: center">
          <div>
            <nz-avatar class="cursor-pointer" [nzSize]="40" [nzIcon]="'eye'" [class]="'ant-avatar-blue'"
              (click)="visualizarArquivo()" nz-tooltip nzTooltipTitle="Clique para visualizar o arquivo"
              nzTooltipPlacement="top"></nz-avatar>
          </div>
          <div class="p-l-10">
            <nz-avatar class="cursor-pointer"
              [nzSize]="40" [nzIcon]="'file'" [class]="'ant-avatar-blue'" (click)="baixarArquivo()" nz-tooltip
              nzTooltipTitle='Clique para fazer download do arquivo' nzTooltipPlacement="top">
            </nz-avatar>
          </div>
         

        </div>

        <div nz-col nzSpan="16" class="p-l-15 p-r-5 align-items-center form-group" style="text-align: end">
          <div *ngIf="processo?.idProcessoPersonalizado" >
            <h5 class="m-b-0">Código do Processo:</h5>
            <h5 class="m-b-0 p-l-5">
              <b>{{ processo?.exercicio + '/' + processo?.idProcessoPersonalizado }}</b>
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="align-items-center p-10 m-t-10 shadow-default" nz-row style="background: #fdfdff">
    <div class="p-l-15 p-t-10 m-b-10 align-items-center" nz-col nzSpan="24">
      <h5 class="m-b-0">Dados do Processo</h5>
      <h6 class="text-color-value">{{ getDataCreatedProcesso() }}</h6>
    </div>

    <div nz-col nzSpan="12" nzSm="12" nzXs="24" class="p-l-15 p-t-10 align-items-center d-flex">
      <div class="form-group">
        <div class="d-flex">
          <h6 class="m-b-0 p-r-5">Tipo do processo:</h6>
          <h6 class="m-b-0 text-color-value">{{ processo.system }}</h6>
        </div>

        <div *ngIf="processo?.id" class="d-flex">
          <h6 class="m-b-0 p-r-5">Código do Processo:</h6>
          <h6 class="m-b-0 text-color-value">{{ processo?.id }}</h6>
        </div>

        <div *ngIf="processo?.cnpjUnidadeGestora" class="d-flex">
          <h6 class="m-b-0 p-r-5">Cnpj da Unidade Gestora:</h6>
          <h6 class="m-b-0 text-color-value">{{ processo?.cnpjUnidadeGestora }}</h6>
        </div>

        <div *ngIf="processo?.system" class="d-flex">
          <h6 class="m-b-0 p-r-5">Sistema:</h6>
          <h6 class="m-b-0 text-color-value">{{ processo?.system }}</h6>
        </div>

        <div *ngIf="processo?.createdAt" class="d-flex">
          <h6 class="m-b-0 p-r-5">Criado:</h6>
          <h6 class="m-b-0 text-color-value">{{ getDataCreatedProcesso(processo?.createdAt) }}</h6>
        </div>

        <div *ngIf="processo?.status" class="d-flex">
          <h6 class="m-b-0 p-r-5">Status:</h6>
          <h6 class="m-b-0 text-color-value">{{ getStatus(processo?.status) }}</h6>
        </div>
      </div>
    </div>

    <div nz-col nzSpan="12" nzSm="12" nzXs="24" class="p-l-15 p-t-10 align-items-center d-flex">
      <div class="form-group">

        <div *ngIf="processo?.usuario?.nPessoa" class="d-flex">
          <h6 class="m-b-0 p-r-5">Nome:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ processo?.usuario?.nPessoa }}
          </h6>
        </div>

        <div *ngIf="processo?.usuario?.endereco" class="d-flex">
          <h6 class="m-b-0 p-r-5">Endereço:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ processo?.usuario?.endereco }}
          </h6>
        </div>

        <div *ngIf="processo?.usuario?.numero" class="d-flex">
          <h6 class="m-b-0 p-r-5">Numero:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ processo?.usuario?.numero }}
          </h6>
        </div>

        <div *ngIf="processo?.usuario?.nBairro" class="d-flex">
          <h6 class="m-b-0 p-r-5">Bairro:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ processo?.usuario?.nBairro }}
          </h6>
        </div>

        <div *ngIf="processo?.usuario?.nCidade" class="d-flex">
          <h6 class="m-b-0 p-r-5">Cidade:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ processo?.usuario?.nCidade }}
          </h6>
        </div>

        <div *ngIf="processo?.usuario?.uf" class="d-flex">
          <h6 class="m-b-0 p-r-5">UF:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ processo?.usuario?.uf }}
          </h6>
        </div>

        <div *ngIf="processo?.usuario?.cep" class="d-flex">
          <h6 class="m-b-0 p-r-5">CEP:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ processo?.usuario?.cep }}
          </h6>
        </div>
      
      </div>
    </div>
  </div>

  <nz-spin *ngIf="anexosDetalhe.length > 0 && anexosDetalhe != null" [nzSpinning]="loadingAnexos" [nzSize]="'large'">
    <div class="align-items-center p-10 m-t-10 shadow-default" nz-row style="background: #fdfdff">

      <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
        <h5>Anexos do Processo</h5>
      </div>

      <div class="col-md-24 col-xl-12 p-b-15">
        <nz-collapse nzGhost>
          <nz-collapse-panel [nzHeader]="arquivosTitle" [nzActive]="false">
            <div nz-row class="d-flex">
              <div nz-col nzSpan="24" nzXs="24">
                <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                  <div class="p-r-10">
                    <div>
                      <div class="p-r-10">
                        
                        <nz-table class="m-b-20" nzSize="small" [nzLoading]="loading" [nzData]="getDetalhe()" #dataDetalhe
                          [nzShowPagination]="true" [nzScroll]="{ y: '380px' }" style="overflow-y: overlay">
                          <thead>
                            <tr>
                              <th style="background: #edf1fd" nzWidth="70px"
                                [nzLeft]="getWidthContent() > 1024">
                              </th>

                              <ng-container>
                                <th nzWidth="110px" [nzSortFn]="getColumns('descricao').compare" [nzSortPriority]="getColumns('descricao').priority" [nzSortOrder]="getColumns('descricao').sortOrder" *ngIf="checkList[getColumns('descricao').value]">
                                  {{ getColumns('descricao').text }}
                                </th>
                              </ng-container>

                              <ng-container>
                                <th nzWidth="180px" [nzSortFn]="getColumns('evento').compare" [nzSortPriority]="getColumns('evento').priority" [nzSortOrder]="getColumns('evento').sortOrder" *ngIf="checkList[getColumns('evento').value]">
                                  {{ getColumns('evento').text }}
                                </th>
                              </ng-container>

                              <ng-container>
                                <th nzWidth="150px" [nzSortFn]="getColumns('arquivoNome').compare" [nzSortPriority]="getColumns('arquivoNome').priority" [nzSortOrder]="getColumns('arquivoNome').sortOrder" *ngIf="checkList[getColumns('arquivoNome').value]">
                                  {{ getColumns('arquivoNome').text }}
                                </th>
                              </ng-container>

                              <ng-container>
                                <th nzWidth="150px" [nzSortFn]="getColumns('createdAt').compare" [nzSortPriority]="getColumns('createdAt').priority" [nzSortOrder]="getColumns('createdAt').sortOrder" *ngIf="checkList[getColumns('createdAt').value]">
                                  {{ getColumns('createdAt').text }}
                                </th>
                              </ng-container>

                              <ng-container>
                                <th nzWidth="150px" [nzSortFn]="getColumns('updatedAt').compare" [nzSortPriority]="getColumns('updatedAt').priority" [nzSortOrder]="getColumns('updatedAt').sortOrder" *ngIf="checkList[getColumns('updatedAt').value]">
                                  {{ getColumns('updatedAt').text }}
                                </th>
                              </ng-container>

                              <th style="background: #edf1fd" nzWidth="110px"
                                [nzRight]="getWidthContent() > 1024">
                                Visualizar
                              </th>

                              <th style="background: #edf1fd" nzWidth="110px"
                                [nzRight]="getWidthContent() > 1024">
                                Baixar
                              </th>

                            </tr>
                          </thead>
                          <tbody>
                              <ng-template ngFor let-item [ngForOf]="dataDetalhe.data" let-indexArr="index">
                                <tr>
                                  <td [nzLeft]="getWidthContent() > 1024" [(nzExpand)]="item.expand">
                                  </td>
                                
                                  <ng-container *ngIf="getColumns('descricao')">
                                    <td>
                                      {{ item?.descricao || null }}
                                    </td>
                                  </ng-container>
  
                                  <ng-container *ngIf="getColumns('evento')">
                                    <td>
                                      <nz-tag ngClass="{{ item?.evento }}">
                                        {{ item?.evento }}</nz-tag>
                                    </td>
                                  </ng-container>
  
                                  <ng-container *ngIf="getColumns('arquivoNome')">
                                    <td>
                                      {{ decodeString(item?.objectData?.files?.filesUploaded[indexArr]?.arquivoNome || '') }}
                                    </td>
                                  </ng-container>
  
                                  <ng-container *ngIf="getColumns('createdAt')">
                                    <td>
                                      {{ item?.createdAt || ''}}
                                    </td>
                                  </ng-container>
  
                                  <ng-container *ngIf="getColumns('updatedAt')">
                                    <td>
                                      {{ item?.updatedAt || '' }}
                                    </td>
                                  </ng-container>
  
                                  <td *ngIf="item?.objectData?.files?.filesUploaded[0]?.arquivoUuid" class="cursor-pointer" [nzRight]="getWidthContent() > 1024">
                                    <div class="d-flex">
                                      <div (click)="getViewAnexo(item?.objectData?.files?.filesUploaded[0]?.arquivoUuid)" class="font-size-25 p-l-20 p-r-20" nz-tooltip
                                        nzTooltipTitle="Clique para visualizar o documento" nzTooltipPlacement="left">
                                        <i nz-icon [nzType]="'file-pdf'" theme="twotone" [twoToneColor]="red"></i>
                                      </div>
                                    </div>
                                  </td>
  
                                  <td *ngIf="item?.objectData?.files?.filesUploaded[0]?.arquivoUuid" (click)="getBaixarAnexo(item?.objectData?.files?.filesUploaded[0]?.arquivoUuid)" class="cursor-pointer" [nzRight]="getWidthContent() > 1024">
                                    <div class="d-flex">
                                      <div class="cursor-pointer"
                                        class="font-size-25 p-l-20 p-r-20" nz-tooltip
                                        nzTooltipTitle="Clique para baixar o documento" nzTooltipPlacement="left">
                                        <i nz-icon [nzType]="'download'"></i>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr [nzExpand]="item?.expand && item?.objectData?.files?.filesUploaded">
                                  <nz-spin *ngIf="item?.expand" [nzSpinning]="loadingAnexos" [nzSize]="'middle'">
                                    <nz-table #anexosData [nzData]="item?.objectData?.files?.filesUploaded" nzSize="middle"
                                      [nzShowPagination]="false">
                                      <thead>
                                        <tr>
                                          <th>Arquivo Nome</th>
                                          <th>Hash Arquivo</th>
                                          <th>Vizualizar</th>
                                          <th>Baixar</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let data of anexosData.data">
                                          <td *ngIf="data?.arquivoNome">{{ decodeString(data?.arquivoNome) }}</td>
                                          <td *ngIf="data?.arquivoUuid">{{ data?.arquivoUuid }}</td>
                                          
                                          <td *ngIf="item?.objectData?.files?.filesUploaded[0]?.arquivoUuid" class="cursor-pointer" [nzRight]="getWidthContent() > 1024">
                                            <div class="d-flex">
                                              <div (click)="getViewAnexo(item?.objectData?.files?.filesUploaded[0]?.arquivoUuid)" class="font-size-25 p-l-20 p-r-20" nz-tooltip
                                                nzTooltipTitle="Clique para visualizar o documento" nzTooltipPlacement="left">
                                                <i nz-icon [nzType]="'file-pdf'" theme="twotone" [twoToneColor]="red"></i>
                                              </div>
                                            </div>
                                          </td>
          
                                          <td *ngIf="item?.objectData?.files?.filesUploaded[0]?.arquivoUuid" (click)="getBaixarAnexo(item?.objectData?.files?.filesUploaded[0]?.arquivoUuid)" class="cursor-pointer" [nzRight]="getWidthContent() > 1024">
                                            <div class="d-flex">
                                              <div class="cursor-pointer"
                                                class="font-size-25 p-l-20 p-r-20" nz-tooltip
                                                nzTooltipTitle="Clique para baixar o documento" nzTooltipPlacement="left">
                                                <i nz-icon [nzType]="'download'"></i>
                                              </div>
                                            </div>
                                          </td>
                                          
                                        </tr>
                                      </tbody>
                                    </nz-table>
                                  </nz-spin>
                                </tr>
                              </ng-template>  
                          </tbody>
                        </nz-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
    </div>
  </nz-spin>

<!-- <div class="align-items-center p-10 m-t-10 shadow-default" nz-row style="background: #fdfdff">
  <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
    <h5>Assinaturas</h5>
  </div>

  <div *ngFor="let assinante of document.signatarios" nz-col nzSpan="8"
    class="p-l-20 p-t-10 p-b-10 align-items-center d-flex">
    <div class="d-flex align-items-center">
      <nz-avatar class="m-r-10" [nzSize]="50" [nzIcon]="getIcoStatusSigner(assinante.status)"
        [class]="'ant-avatar-' + getColorStatusSigner(assinante.status)" nz-tooltip
        [nzTooltipTitle]="statusDocLabel[assinante.status]" nzTooltipPlacement="left">
      </nz-avatar>
      <div>
        <h5 class="m-b-0 p-r-5">{{ assinante.nome || assinante.razaoSocial }}</h5>
        <div *ngIf="assinante.razaoSocial">
          <p class="m-b-0 p-r-5 text-color-value">Representante: {{assinante.nomeRepresentante}}</p>
        </div>
        <div>
          <p class="m-b-0 p-r-5 text-color-value">{{getLabelByStatus(assinante)}}:</p>
          <p class="m-b-0 text-color-value">
            {{
            getDateSigned(assinante)
            }}
          </p>
        </div>
      </div>

      <div class="is-mobile">
        <h5 class="m-b-0 p-r-5 text-truncate" style="max-width: 400px">
          {{ assinante.nome || assinante.razaoSocial}}
        </h5>

        <div class="">
          <p class="fs-12 m-b-0 p-r-5 text-color-value">
            {{getLabelByStatus(assinante)}}:
          </p>
          <p class="fs-12 m-b-0 text-color-value">
            {{
            getDateSigned(assinante)
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div> -->

<nz-spin [nzSpinning]="loadingPage" [nzSize]="'large'">
  <div class="align-items-center p-10 m-t-10 shadow-default" nz-row style="background: #fdfdff">

    <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
      <h5>Linha do tempo</h5>
    </div>

    <div class="p-r-15 p-l-15 p-t-15" nz-col nzSpan="24">
      <nz-timeline nzMode="alternate">
        <nz-timeline-item [nzDot]="template" [nzColor]="black" *ngFor="let detalhe of listDetalhe">
          <p>{{ detalhe?.createdAt | date:'dd/MM/yyyy HH:mm:ss' }}</p>
          {{ detalhe?.descricao }}
          <ng-template #template>
            <i nz-icon [nzType]="file" style="font-size: 22px"></i>
          </ng-template>
        </nz-timeline-item>
      </nz-timeline>
    </div>
  </div>
</nz-spin>

</nz-spin>
<!-- <ps-fab *ngIf="document?.documentoAnexo?.length" [actions]="fabButtons"></ps-fab>   -->
  <!-- <div>
  <div>
    <div class="align-items-center shadow-default" nz-row style="background: #edf1fd;">
      <div nz-col nzSpan="10">
        <div class="media align-items-center">
          <div class="p-l-15">
            <h5 class="m-b-0">Número do processo:</h5>
            <h4 class="m-b-0">
              <b>{{processo.exercicio}}/{{processo.idProcessoPersonalizado}}</b>
            </h4>
            
          </div>
        </div>
      </div>
      <div>
        <nz-avatar class="cursor-pointer" (click)="visualizarArquivo()" [nzSize]="40"
          [nzIcon]="'eye'" [class]="'ant-avatar-blue'" nz-tooltip
          nzTooltipTitle="Clique para visualizar o comprovante do processo" nzTooltipPlacement="top">
        </nz-avatar>
      </div>
      <div class="p-l-10">
        <nz-avatar class="cursor-pointer" (click)="baixarArquivo()" [nzSize]="40"
          [nzIcon]="'file'" [class]="'ant-avatar-blue'" nz-tooltip nzTooltipTitle="Clique para fazer o download do comprovante do processo"
          nzTooltipPlacement="top"></nz-avatar>
      </div>
      <div class="p-r-5" nz-col nzSpan="9" nzOffset="2">
        <div nz-row>
          <div nz-col nzSpan="24" class="p-l-15 align-items-center form-group" style="text-align: end">
            <h5 class="m-b-0">Processo aberto por:</h5>
            <h5 class="m-b-0 p-l-5 p-r-10">
              <h4 class="m-b-0">
                <b>{{processo.usuario.nPessoa}}</b>
  
              </h4>
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div class="align-items-center shadow-default" nz-row style="background: #edf1fd;">
      <div class="p-b-15 p-t-10 m-b-10 align-items-center" nz-col nzSpan="24">
        <h5 class="m-b-0">Dados do Arquivo</h5>
        <h6 class="text-color-value"></h6>
      </div>
    </div>
  </div>
</div> -->