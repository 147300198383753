import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { SolicitacaoCancelamentoNFSEValidatorsService } from 'libs/shared/src/lib/services/solicitacao-cancelamento-nfse-validators.service';
import { SolicitacaoCancelamentoNFSEService } from 'libs/shared/src/lib/services/solicitacao-cancelamento-nfse.service';
import { ServiceResponse } from 'libs/shared/src/lib/utils';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'solicitacao-cancelamento-nfse',
  templateUrl: './solicitacao-cancelamento-nfse.component.html',
  styleUrls: ['./solicitacao-cancelamento-nfse.component.scss'],
})
export class SolicitacaoCancelamentoNFSEComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private notification: NzNotificationService,
    private solicitacaoCancelamentoNFSEService: SolicitacaoCancelamentoNFSEService,
    private modalService: NzModalService,
    private validatorsService: SolicitacaoCancelamentoNFSEValidatorsService,
    private cookieService: CookieService
  ) {
    this.resetFabButtons();

    this.formB = this.formBuilder.group({
      numeroNota: [null, Validators.required],
      periodo: [
        null,
        [Validators.required, validatorsService.periodoValidator()],
      ],
      cpfCnpjTomador: [null, validatorsService.cnpjValidator()],
      cnpjPrestador: [null, validatorsService.cnpjValidator()],
      motivo: null,
      anexosFile: this.formBuilder.array([]),
    });
  }

  tabIndex: 0 | 1 = 0;
  fabButtons: IMenuButton[];
  formB: FormGroup = new FormGroup({});
  value?: string;
  anexos: NzUploadFile[] = [];
  loadingPage: boolean = false;
  loadingModal: boolean = false;
  loadingTable: boolean = false;
  displayData = [];
  checkAllIndeterminate: boolean = false;
  checkedAll;
  maxFileSize = 4194304;
  anexoErro = false;
  cancelarNFSEModal = false;
  okCancelarNFSE = false;
  dataNFSEModal: ServiceResponse = { msg: '', data: null };
  processoData: ServiceResponse = { msg: '', data: null };

  formData = new FormData();
  formParams;

  tableConsultaCancelamentoNFSEColumns;

  fieldConsultaCancelametoNFSESelected;
  fieldsConsultaCancelametoNFSESelected = [];
  arrColumnsCancelametoNFSESelect = [];
  selectConsultaCancelamentoNFSEValues;

  showComboTagsConsultaCancelamentoNFSE = false;

  checkList = {
    numNota: true,
    status: true,
    periodo: true,
    cnpjPrestador: true,
    cnpjTomador: true,
    descricao: true,
    dtCriacao: true,
    dtAlteracao: true,
    entidadeAnexos: true,
  };

  ngOnInit(): void {
    this.resetFabButtons();
    this.defaultFormValues();

    //this.setConfigCancelamentoPropsTable();
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  reloadPage() {}

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: this.tabIndex === 1 || this.tabIndex === 0,
        onClick: () => null,
      },
      {
        icon: 'send',
        tooltip: 'Enviar ou reenviar',
        color: 'green',
        condition: this.tabIndex === 1,
        onClick: () => null,
      },
      {
        icon: 'clock-circle',
        tooltip: 'programar',
        color: 'blue',
        condition: false,
        onClick: () => null,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: () => this.onOk(),
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: () => null,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: () => null,
      },
      // {
      //   icon: 'search',
      //   tooltip: 'Consultar',
      //   condition: this.tabIndex === 0,
      //   onClick: () => null,
      // },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        // color: 'blue',
        condition: true,
        onClick: () => this.reloadPage(),
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  formatFields(nomeField, field) {
    if (nomeField === 'periodo') {
      return field.slice(4) + '/' + field.slice(0, 4);
    }

    if (nomeField === 'data') {
      if (field == null) {
        return '';
      }
      return moment(field).format('DD/MM/yy');
    }

    if (nomeField === 'valor') {
      return 'R$ ' + field.toFixed(2);
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;
    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  updateAllChecked() {
    if (this.displayData.length != 0) {
      this.checkAllIndeterminate = !this.checkAllIndeterminate;
      this.checkedAll = !this.checkedAll;
      this.displayData.forEach((e) => (e.checked = this.checkedAll));
    }
    let map = [];
    this.displayData.forEach((e) => map.push(e.checked));
  }

  removeFile = (file: NzUploadFile): boolean => {
    // for (let i = 0; i < this.anexos.length; i++) {
    //   this.anexos.splice(i);
    // }
    const index = this.anexos.findIndex((anexo) => anexo.uid === file.uid);
    this.anexos.splice(index, 1);
    return true;
  };

  getWidthContent() {
    return window.innerWidth;
  }

  upload = (file: NzUploadFile): boolean => {
    this.anexoErro = false;

    if (file.size > this.maxFileSize) {
      this.anexoErro = true;
      this.notification.error(
        'Documento',
        'Selecione um documento menor que 4mb!'
      );

      return false;
    }

    const entidadeAnexosForm: FormArray = this.formB.get(
      'anexosFile'
    ) as FormArray;

    entidadeAnexosForm.push(
      this.formBuilder.group({
        file,
      })
    );

    this.anexos = this.anexos.concat(file);
    return false;
  };

  getFilterConsultaCancelamentoNFSEOptionsSelect(column) {
    let arrTypes = [];
    this.selectConsultaCancelamentoNFSEValues = [];

    for (const at of arrTypes) {
      this.selectConsultaCancelamentoNFSEValues.push({
        field: this.fieldConsultaCancelametoNFSESelected.text,
        value: column.objectChildren === 'tag' ? at.tag : at.tipo,
        text:
          column.objectChildren === 'tag'
            ? at.tag
            : `${at.numNota} - ${at.tipo}`,
      });
    }
  }

  filterMensagensEnvio() {
    return true;
  }

  // async setConfigCancelamentoPropsTable() {
  //   this.loadingPage = false;
  //   await this.solicitacaoCancelamentoNFSEService
  //     .getSolicitacaoCancelamentoNFSEProps()
  //     .then((result) => {
  //       const tableColumnsSolicitacaoCancelamentoNFSE = result.tableColumnsAnexo.map(
  //         (column) => {
  //           return {
  //             ...column,
  //             compare:
  //               column.value === 'numeroNota'
  //                 ? (a, b) => a[column.value] - b[column.value]
  //                 : (a, b) => {
  //                     const string = a[column.value] ?? ' ';
  //                     return string.localeCompare(b[column.value] ?? ' ');
  //                   },
  //             width:
  //               column.value === 'numeroNota'
  //                 ? '5.7rem'
  //                 : column.value === 'status'
  //                 ? '8rem'
  //                 : column.value === 'cnpjContribuinte'
  //                 ? '13rem'
  //                 : column.value === 'cpfCnpjTomador'
  //                 ? '13rem'
  //                 : column.value === 'motivo'
  //                 ? '36rem'
  //                 : column.value === 'periodo'
  //                 ? '10rem'
  //                 : column.value === 'dtCriacao'
  //                 ? '10rem'
  //                 : null,
  //             type: column.type,
  //           };
  //         }
  //       );
  //       this.tableConsultaCancelamentoNFSEColumns = tableColumnsSolicitacaoCancelamentoNFSE;
  //       this.arrColumnsCancelametoNFSESelect = result.searchColumns;
  //       return;
  //     });
  //   this.loadingPage = false;
  // }

  exitCancelarNFSEModal() {
    return (this.cancelarNFSEModal = false);
  }

  async okCancelarNFSEModal() {
    this.cancelarNFSEModal = false;

    return await this.salvar();
  }

  async onOk() {
    if (!this.formB.valid) {
      this.notification.error(
        'Formulário',
        'Preencha corretamente o formulário'
      );
      return;
    }

    try {
      this.formParams = {
        codigoInterno: this.formB.get('numeroNota').value,
        periodo:
          this.formB.get('periodo').value.slice(2) +
          this.formB.get('periodo').value.slice(0, 2),
        cnpjContribuinte: this.formB.get('cnpjPrestador').value,
        tipoNota: 1,
      };

      const filteredParams = {};

      for (const key in this.formParams) {
        const value = this.formParams[key];
        if (value !== null) {
          filteredParams[key] = value;
        }
      }

      this.loadingPage = true;
      this.dataNFSEModal = await this.solicitacaoCancelamentoNFSEService.consultaNotaFiscal(
        filteredParams
      );

      this.loadingPage = false;

      if (!this.dataNFSEModal.data) {
        throw new Error();
      }

      this.cancelarNFSEModal = true;
    } catch (error) {
      this.loadingPage = false;
      this.notification.error(
        'NFSE não encontrada',
        'Verifique corretamente os dados preenchidos.'
      );
    }
  }

  salvar = async () => {
    this.formData.append('motivo', this.formB.get('motivo').value);

    const anexosFileArray = this.formB.get('anexosFile') as FormArray;
    for (let i = 0; i < anexosFileArray.length; i++) {
      const fileControl = anexosFileArray.at(i) as FormGroup;
      const file: File = fileControl.get('file').value;

      this.formData.append('docsMotivos', file, file.name);
    }

    this.loadingPage = true;
    try {
      this.processoData = await this.solicitacaoCancelamentoNFSEService.cancelamentoNFSE(
        this.formParams,
        this.formData
      );

      this.modalSucessProcesso();

      this.notification.success(
        'Solicitção Enviada',
        'Solicitacação de cancelamento enviado com sucesso!'
      );
    } catch (error) {
      this.notification.error('Erro no pedido de cancelamento', error);
    } finally {
      this.formParams = null;
      this.formData = new FormData();
      this.anexos = [];
      const anexosFileArray = this.formB.get('anexosFile') as FormArray;
      while (anexosFileArray.length !== 0) {
        anexosFileArray.removeAt(0);
      }

      this.formB.reset();
      this.loadingPage = false;
    }
  };

  defaultFormValues() {
    const localInfoData = JSON.parse(localStorage.getItem('local-info'))

    if (localInfoData) {
      this.formB.patchValue({
        numeroNota: localInfoData.nota,
        periodo: localInfoData.periodo.slice(4) + localInfoData.periodo.slice(0,4),
        cnpjPrestador: localInfoData.contribuinte,
      })
    }
  }

  modalSucessProcesso() {
    this.modalService.success({
      nzTitle: 'Segue o Código do Processo:',
      nzContent: this.processoData.data.id,
    });
  }
}
