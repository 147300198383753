import { StatusDocumento } from '../enum/const.enum';

export const StatusProcessDocLabel = {
  [StatusDocumento.Pendente]: 'Pendente',
  [StatusDocumento.Confirmado]: 'Concluído',
  [StatusDocumento.Desacordo]: 'Desacordo',
  [StatusDocumento.Expirado]: 'Expirado',
  [StatusDocumento.Cancelado]: 'Cancelado',
};

export const IconsStatusProcessDoc = {
  [StatusDocumento.Pendente]: 'field-time',
  [StatusDocumento.Confirmado]: 'check-circle',
  [StatusDocumento.Desacordo]: 'close-circle',
  [StatusDocumento.Expirado]: 'history',
  [StatusDocumento.Cancelado]: 'stop',
  [StatusDocumento.PendenteRatificacao]: 'field-time',
};

export const ColorStatusProcessDoc = {
  [StatusDocumento.Pendente]: 'blue',
  [StatusDocumento.Confirmado]: 'green',
  [StatusDocumento.Desacordo]: 'red',
  [StatusDocumento.Expirado]: 'orange',
  [StatusDocumento.Cancelado]: 'dark',
  [StatusDocumento.PendenteRatificacao]: 'blue',
};

export const Teste = {
  0: 'Prefeitura',
};

export const TypeDocDownload = {
  [StatusDocumento.Pendente]: 'DownloadOriginal',
  [StatusDocumento.Confirmado]: 'DownloadAssinado',
  [StatusDocumento.Desacordo]: 'DownloadDesacordo',
  [StatusDocumento.Cancelado]: 'DownloadCancelado',
};

export const TypeDocView = {
  [StatusDocumento.Pendente]: 'Visualizar',
  [StatusDocumento.Confirmado]: 'VisualizarAssinado',
  [StatusDocumento.Desacordo]: 'VisualizarDesacordado',
  [StatusDocumento.Cancelado]: 'VisualizarCancelado',
};

export const DateLabel = {
  [StatusDocumento.Assinado]: 'Data da assinatura',
  [StatusDocumento.Pendente]: 'Pendente de assinatura',
  [StatusDocumento.Desacordo]: 'Data do desacordo',
  [StatusDocumento.Cancelado]: 'Data do cancelamento',
  [StatusDocumento.PendenteRatificacao]: 'Pendente de ratificação',
};

export const TypeActions = {
  [StatusDocumento.Pendente]: 'Visualizar',
  [StatusDocumento.Confirmado]: 'VisualizarAssinado',
  [StatusDocumento.Desacordo]: 'VisualizarDesacordado',
  [StatusDocumento.Cancelado]: 'VisualizarCancelado',
};

export const StatusDocLabel = {
  [StatusDocumento.Assinado]: 'Assinado',
  [StatusDocumento.Confirmado]: 'Concluído',
  [StatusDocumento.Desacordo]: 'Desacordo',
  [StatusDocumento.Pendente]: 'Pendente',
  [StatusDocumento.Expirado]: 'Expirado',
  [StatusDocumento.Cancelado]: 'Cancelado',
  [StatusDocumento.PendenteRatificacao]: 'Pendente de Ratificação',
};

export const StatusDoc = {
  ['Assinado']: StatusDocumento.Assinado,
  ['Confirmado']: StatusDocumento.Confirmado,
  ['Desacordo']: StatusDocumento.Desacordo,
  ['Pendente']: StatusDocumento.Pendente,
  ['Expirado']: StatusDocumento.Expirado,
  ['Cancelado']: StatusDocumento.Cancelado,
  ['Pendente de Ratificação']: StatusDocumento.PendenteRatificacao,
};

export const IconsStatusDoc = {
  [StatusDocumento.Assinado]: 'form',
  [StatusDocumento.Confirmado]: 'check-circle',
  [StatusDocumento.Desacordo]: 'close-circle',
  [StatusDocumento.Pendente]: 'field-time',
  [StatusDocumento.Expirado]: 'history',
  [StatusDocumento.Cancelado]: 'stop',
  [StatusDocumento.PendenteRatificacao]: 'field-time',
};

export const ColorStatusDoc = {
  [StatusDocumento.Assinado]: 'blue',
  [StatusDocumento.Confirmado]: 'green',
  [StatusDocumento.Desacordo]: 'red',
  [StatusDocumento.Pendente]: 'gold',
  [StatusDocumento.Expirado]: 'orange',
  [StatusDocumento.Cancelado]: 'dark',
  [StatusDocumento.PendenteRatificacao]: 'gold',
};

export const IntervalsTime = {
  [7]: 'Dia',
  [4]: 'Semana',
  [6]: 'Mês',
  [5]: 'Ano',
  [1]: 'Outros',
};

export const IntervalsTimeTranslate = {
  ['Dia']: 'Day',
  ['Semana']: 'Week',
  ['Mês']: 'Month',
  ['Ano']: 'Year',
  ['Outros']: 'Others',
};
