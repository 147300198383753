import { Component, OnInit } from '@angular/core';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import {
  Helper,
  IColumnsFieldsProps,
  ISearchFieldsProps,
  ProcessoService,
  SolicitacaoITBI,
  StatusProcesso,
} from '@ps-erp-angular/shared';
import { DetalheProcessoInterface } from 'libs/shared/src/lib/interfaces/detalhe.processo-interface';
import { ProcessoInterface } from 'libs/shared/src/lib/interfaces/processo-interface';
import * as moment from 'moment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ModalProcessoDetalhe } from './modalProcessoDetalhe/modalProcessoDetalhe.component';

@Component({
  selector: 'processo-contribuinte',
  templateUrl: './processo.component.html',
  styleUrls: ['./processo.component.scss'],
})
export class ProcessoComponent implements OnInit {
  getDateNow: any = new Date().toLocaleString();
  selectedValue;
  public defaultSortColumn = 'dtAlteracao';

  listOfColumn = [
    {
      text: 'Código',
      sortOrder: null,
      value: 'codigo',
      // compare: (a: ProcessoInterface, b: ProcessoInterface) =>
      //   a.usuario.nome.localeCompare(b.usuario.nome),
      priority: false,
    },
    {
      text: 'Data criação',
      sortOrder: null,
      value: 'dtCriacao',
      compare: (a: ProcessoInterface, b: ProcessoInterface) =>
        a.createdAt.localeCompare(b.createdAt),
      priority: 2,
    },
    {
      text: 'Atualizado',
      sortOrder: 'descend',
      value: 'dtAlteracao',
      compare: (a: ProcessoInterface, b: ProcessoInterface) =>
        a.updatedAt.localeCompare(b.updatedAt),
      priority: 1,
    },
    {
      text: 'Hash',
      sortOrder: null,
      value: 'id',
      compare: (a: ProcessoInterface, b: ProcessoInterface) =>
        a.id.localeCompare(b.id),
      priority: 4,
    },
    {
      text: 'Status',
      sortOrder: null,
      value: 'status',
      compare: (a: ProcessoInterface, b: ProcessoInterface) =>
        a.status.localeCompare(b.status),
      priority: 3,
    },
    {
      floatRigth:true,
      text: 'Comprovante',
      sortOrder: null,
      value: 'comprovante',
      compare: (a: ProcessoInterface, b: ProcessoInterface) =>
        a.comprovante.localeCompare(b.comprovante),
      priority: 5,
    },
  ];

  fieldSelected: any;
  fieldsSelected = [];
  searchInput;
  selectedValues = [];
  showComboTags = false;

  dataPicker = false;
  campoCodigo = false;

  detalheProcesso: DetalheProcessoInterface[] = [];

  opcoesFiltro = [
    {
      value: 'id',
      text: 'Hash',
      type: 'input',
      enabled: true,
    },
    {
      value: 'createdAt',
      text: 'Data de Criação',
      type: 'range-picker',
      enabled: true,
    },
    {
      value: 'updatedAt',
      text: 'Data de Atualização',
      type: 'range-picker',
      enabled: true,
    },
    {
      value: 'status',
      text: 'Status',
      type: 'statusOption',
      enabled: true,
    },
    // {
    //   value: 'usuario',
    //   text: 'Usuario',
    //   type: 'input',
    //   enabled: false,
    // },
  ];

  opcoesStatus = [
    {
      value: 'pendente',
      text: 'PENDENTE',
      enabled: true,
      valueInt: StatusProcesso.PENDENTE,
    },
    {
      value: 'deferido',
      text: 'DEFERIDO',
      enabled: true,
      valueInt: StatusProcesso.ARQUIVO_DEFERIDO
    },
    {
      value: 'indeferido',
      text: 'INDEFERIDO',
      enabled: true,
      valueInt: StatusProcesso.ARQUIVO_INDEFERIDO
    },
    {
      value: 'agurdando_pagamento',
      text: 'AGUARDANDO PAGAMENTO',
      enabled: true,
      valueInt: StatusProcesso.AGUARDANDO_PAGAMENTO
    },
  ];

  fabButtons: IMenuButton[] = [];

  searchColumns: ISearchFieldsProps[] = [];
  tableColumns: IColumnsFieldsProps[] = [];

  checkList = {
    codigo: true,
    id: true,
    usuario: true,
    unidadeGestora: true,
    dtCriacao: true,
    dtAlteracao: true,
    status: true,
    comprovante: true
  };

  loadingPage = false;
  loadingTable = false;

  checked = false;
  indeterminate = false;

  listProcessos: ProcessoInterface[] = [];
  fieldEnvioSelected;
  fieldsMensagensEnvioSelected = [];
  setOfCheckedId = new Set<number>();

  panels = [
    {
      active: true,
      name: 'Filtros',
    },
  ];

  constructor(
    private processoService: ProcessoService,
    private solicitaITBIService: SolicitacaoITBI,
    private notification: NzNotificationService,
    private modal: NzModalService
    ) {}

  async ngOnInit() {
    this.resetFabButtons();
  }

  getWindow() {
    return window.innerWidth;
  }

  changeValue(value) {
    this.dataPicker = value === 'dtCriacao' || value === 'dtAlteracao';
    this.campoCodigo = value === 'id';
  }

  async getProcessos() {
    try {
      const params = Helper.convertSelectedFieldsToURLParams(
        this.fieldsSelected
      );

      this.loadingPage = true;
      this.listProcessos = await this.processoService.getProcessos(params);

      this.listProcessos.forEach(processo => {
        processo.expand  = false;
      })

    } finally {
      this.loadingPage = false;
    }
  }


  async getDetalheProcesso(idProcesso) {
    try {
      this.loadingPage = true

      const param = { 'idProcesso': idProcesso }
      this.detalheProcesso = await this.processoService.getDetalhe(param)
    
      
    } catch (error) {
      this.loadingPage = false;
    } finally {
      this.loadingPage = false;
    }
  }

  attDashboard = () => {
    this.reset();
    this.getDate();
  };

  async reset() {
    await this.getProcessos();
  }

  resetarListColumns() {
    this.checkList = {
      codigo: true,
      id: true,
      usuario: true,
      unidadeGestora: true,
      dtCriacao: true,
      dtAlteracao: true,
      status: true,
      comprovante: true
    };
  }

  getDate() {
    this.getDateNow = new Date().toLocaleString();
  }

  public getStatus(status: any): string {
    switch (status) {
      case StatusProcesso.PENDENTE || 'PENDENTE':
        return 'PENDENTE';
      case StatusProcesso.ARQUIVO_DEFERIDO || 'DEFERIDO':
        return 'DEFERIDO';
      case StatusProcesso.ARQUIVO_INDEFERIDO || 'INDEFERIDO':
        return 'INDEFERIDO';
      case StatusProcesso.AGUARDANDO_PAGAMENTO || 'AGUARDANDO_PAGAMENTO':
        return 'AGUARDANDO_PAGAMENTO'
    }
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        // color: 'blue',
        condition: true,
        onClick: this.attDashboard,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  getOptionsSelect() {}

  getColumns(column) {
    return this.listOfColumn.find((columnObj)=> columnObj.value == column)
  }

  navigateDetalhe(processo) {
    this.showModalDetalhe(processo)
  }

  showModalDetalhe(processo) {
    const configs: any = { footer: false, header: false };
    this.modal.create({
      nzTitle: `<h4>Detalhes do processo</h4>`,
      nzWidth: window.innerWidth * 0.6,
      nzContent: ModalProcessoDetalhe,
      nzComponentParams: { processo },
      nzFooter: [],
    });
  }

  addFiltroPorCampo() {
    if (this.fieldSelected && this.searchInput) {
      if (this.fieldSelected.type === 'range-picker') {
        this.fieldsSelected = this.fieldsSelected.filter((el) => {
          return el.field != 'Data do documento';
        });
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }

      if (this.fieldSelected.type === 'statusOption') {
        this.fieldsSelected.push({
          props: this.fieldSelected.value,
          field: this.fieldSelected.text,
          value: this.searchInput.valueInt,
          text: `${this.fieldSelected.text} - ${
            this.searchInput.text ? this.searchInput.text : this.searchInput
          }`,
        });
      }

      if(!(this.fieldSelected.type == 'statusOption')) {
        this.fieldsSelected.push({
          props: this.fieldSelected.value,
          field: this.fieldSelected.text,
          value: this.searchInput.text ? this.searchInput.text : this.searchInput,
          text: `${this.fieldSelected.text} - ${
            this.searchInput.text ? this.searchInput.text : this.searchInput
          }`,
        });
      }
      
      const filtro = this.opcoesFiltro.find(
        (item) => item.value === this.fieldSelected.value
      );

      filtro.enabled = false;
      this.showComboTags = true;
      this.fieldSelected = '';
      this.searchInput = '';
      this.selectedValues = [];
    }
  }

  get filtros() {
    return this.opcoesFiltro.filter((item) => item?.enabled);
  }

  removeFiltroPorField(index) {
    const filtro = this.opcoesFiltro.find(
      (item) => item.value === this.fieldsSelected[index].props
    );

    filtro.enabled = true;

    this.fieldsSelected.splice(index, 1);
    if (this.fieldsSelected.length === 0) {
      this.showComboTags = false;
    }
  }

  async getComprovante(idProcesso) {
    try {
      this.loadingPage = true;
      await this.solicitaITBIService.viewComprovante(idProcesso);
    } catch (error) {
      this.notification.error('Processo', error?.message || error)
    } finally {
      this.loadingPage = false;
    }
  }

  validaFileTipoProcesso(idTipoProcesso): boolean {
    if(idTipoProcesso != 1) {
      return false;
    }
    return true;
  }


}
