import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  IColumnsFieldsProps,
  ISearchFieldsProps,
} from './../interfaces/shared.interface';
import { ServiceResponse } from './../utils';
import { AbstractService } from './abstract.service';
import { AuthService } from './api/auth.service';

@Injectable()
export class SolicitacaoCancelamentoNFSEService extends AbstractService {
  constructor(
    public abstractHttp: HttpClient,
    public abstractAuthService: AuthService,
    public abstractNotification: NzNotificationService
  ) {
    super(
      'solicitacao-cancelamento-nfse',
      abstractHttp,
      abstractAuthService,
      abstractNotification
    );
  }

  async getSolicitacaoCancelamentoNFSEProps(): Promise<any> {
    return new Promise((resolve, reject) => {
      const campoPesquisa: ISearchFieldsProps[] = [];
      const colunasTabela: IColumnsFieldsProps[] = [];

      this.propsSolicitacaoCancelamentoNFSE()
        .then((data) => {
          for (const d in data) {
            if (data[d].isSearch) {
              campoPesquisa.push({
                text: data[d].label,
                value: d,
                type: data[d].type,
              });
            }
          }

          for (const d in data) {
            if (data[d].columnTable) {
              colunasTabela.push({
                text: data[d].label,
                value: d,
                mask: data[d].mask ? data[d].mask : null,
                objectChildren: data[d].hasOwnProperty('objectChildren')
                  ? data[d].objectChildren[0]
                  : null,
                type: data[d].type,
              });
            }
          }

          const result = {
            searchColumns: campoPesquisa,
            tableColumnsAnexo: colunasTabela,
          };

          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async propsSolicitacaoCancelamentoNFSE() {
    const data = {
      numeroNota: {
        isSearch: true,
        label: 'Número Nota',
        columnTable: true,
        type: 'input',
      },
      periodo: {
        isSearch: false,
        label: 'Período',
        columnTable: true,
        type: 'select',
      },
      status: {
        isSearch: false,
        label: 'Status',
        columnTable: true,
        type: 'select',
      },
      cnpjContribuinte: {
        isSearch: true,
        label: 'CNPJ Prestador',
        columnTable: true,
        type: 'input',
      },
      cpfCnpjTomador: {
        isSearch: true,
        label: 'CNPJ Tomador',
        columnTable: true,
        type: 'input',
      },
      descricao: {
        isSearch: true,
        label: 'Descrição',
        column: true,
        type: 'input',
      },
      dtCriacao: {
        isSearch: true,
        label: 'Data Criação',
        columnTable: true,
        type: 'range-picker',
      },
      motivo: {
        isSearch: false,
        label: 'Status',
        columnTable: true,
        type: 'select',
      },
    };

    return data;
  }

  async cancelamentoNFSE(
    formParams,
    formData: FormData
  ): Promise<ServiceResponse> {
    const params = new URLSearchParams(formParams).toString();

    const url = `${this.getServer('solicitacao-cancelamento-nfse')}/?${params}`;

    const localInfoString = localStorage.getItem('local-info');
    const localInfo = JSON.parse(localInfoString);

    const response = (await this.http
      .post(url, formData, {
        headers: new HttpHeaders({
          //'x-authorization': `${localInfo.cnpjInst}`,
          'x-authorization': `09073628000191`
        }),
      })
      .toPromise()) as ServiceResponse;
    return response;
  }

  async consultaNotaFiscal(formParams): Promise<ServiceResponse> {
    const params = new URLSearchParams(formParams).toString();

    const url = `${this.getServer('get-nfse')}/get-one?${params}`;

    const localInfoString = localStorage.getItem('local-info');
    const localInfo = JSON.parse(localInfoString);

    const response = (await this.http
      .get(url, {
        headers: new HttpHeaders({
          //'x-authorization': `${localInfo.cnpjInst}`,
          'x-authorization': `09073628000191`
        }),
      })
      .toPromise()) as ServiceResponse;

    return response;
  }

  async verificaToken(token): Promise<ServiceResponse> {
    const url = `${this.getServer('verificaToken')}?token=${token}`;

    const localInfoString = localStorage.getItem('local-info');
    const localInfo = JSON.parse(localInfoString);

    const response = (await this.http
      .get(url, {
        headers: new HttpHeaders({
          //'x-authorization': `${localInfo.cnpjInst}`,
          'x-authorization': `09073628000191`,
        }),
      })
      .toPromise()) as ServiceResponse;

    return response;
  }
}
