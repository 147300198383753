import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { PsComponentsModule } from './components/ps-components.module';
import { PsUiConfig, PS_CONFIG } from './config/ps-config';
import { PsDirectiveModule } from './directives';
import { TabSessionCanDeactivateGuard } from './guards';
import { AuthGuard } from './guards/auth.guard';
import { PsLayoutModule } from './layouts';
import { PsPagesModule } from './pages';
import { CustomReuseStrategy } from './utils/custom-route-reuse';

import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {
  HttpErrorInterceptor,
  JwtInterceptor,
  MenuService,
  StorageService,
  TableService,
  ThemeService,
} from '@ps-erp-angular/shared';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
registerLocaleData(localePt);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    PsComponentsModule,
    PsPagesModule,
    PsLayoutModule,
    PsDirectiveModule,
  ],
  exports: [
    PsComponentsModule,
    PsDirectiveModule,
    PsPagesModule,
    PsLayoutModule,
  ],
  providers: [
    AuthGuard,
    ThemeService,
    MenuService,
    StorageService,
    TableService,
    NzNotificationService,
    NzModalService,
    { provide: NZ_I18N, useValue: pt_BR },
    // { provide: NZ_ICONS, useValue: icons },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [NzNotificationService],
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    TabSessionCanDeactivateGuard,
  ],
})
export class PsUiModule {
  static forRoot(config: PsUiConfig): ModuleWithProviders<any> {
    return {
      ngModule: PsUiModule,
      providers: [
        {
          provide: PS_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
