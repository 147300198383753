import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzUploadModule } from 'ng-zorro-antd/upload';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  PsComponentsModule,
  PsDirectiveModule,
  PsDynamicFormModule,
  PsGridModule,
  PsUiModule,
} from '@ps-erp-angular/ps-ui';
import { ProcessoService, SolicitacaoAlvaraService, SolicitacaoITBI } from '@ps-erp-angular/shared';
import { SolicitacaoCancelamentoNFSEValidatorsService } from 'libs/shared/src/lib/services/solicitacao-cancelamento-nfse-validators.service';
import { SolicitacaoCancelamentoNFSEService } from 'libs/shared/src/lib/services/solicitacao-cancelamento-nfse.service';
import { SolicitacaoITBIValidatorsService } from 'libs/shared/src/lib/services/solicitacao-itbi-validators.service';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuardContribuinte } from './guard-contribuinte.service';
import { LayoutComponent } from './layout/layout.component';
import { ModalProcessoDetalhe } from './processo/modalProcessoDetalhe/modalProcessoDetalhe.component';
import { ProcessoComponent } from './processo/processo.component';
import { SolicitacaoAlvaraComponent } from './solicitacao-alvara/solicitacao-alvara.component';
import { SolicitacaoCancelamentoNFSEComponent } from './solicitacao-cancelamento-nfse/solicitacao-cancelamento-nfse.component';
import { SoliciticaoITBIComponent } from './soliciticao-itbi/soliciticao-itbi.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    SoliciticaoITBIComponent,
    SolicitacaoCancelamentoNFSEComponent,
    ProcessoComponent,
    ModalProcessoDetalhe,
    SolicitacaoAlvaraComponent

  ],
  imports: [
    NzTabsModule,
    NzTreeModule,
    NzModalModule,
    NzRadioModule,
    NzUploadModule,
    NzDividerModule,
    NzTagModule,
    NzIconModule,
    NzInputModule,
    NzCardModule,
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxMaskModule,
    AppRoutingModule,
    PsDynamicFormModule.forRoot({
      validationMessages: [{ name: 'pattern', message: 'Formato invalido' }],
    }),
    PsGridModule.forRoot({ gridType: 'antd' }),
    PsUiModule.forRoot({
      appName: 'Portal do Contribuinte',
      appVersion: '1.0.0',
    }),
    PsComponentsModule,
    PsDirectiveModule,
    NzCollapseModule,
    NzTableModule,
    NzSpinModule,
    NzPaginationModule,
    NzDropDownModule,
    NzButtonModule,
    NzGridModule,
    NzFormModule,
    NzSelectModule,
    NzCascaderModule,
    NzCheckboxModule,
    NzAvatarModule,
    NzDatePickerModule,
    NzBadgeModule,
    NzToolTipModule,
    NzTimelineModule 
  ],
  providers: [
    AuthGuardContribuinte,
    SolicitacaoCancelamentoNFSEService,
    SolicitacaoCancelamentoNFSEValidatorsService,
    SolicitacaoITBIValidatorsService,
    ProcessoService,
    SolicitacaoITBI,
    SolicitacaoAlvaraService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
