import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ps-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  public modulo: string = 'Portal do Contribuinte';
  public ug: string;
  public brasao: string
  constructor() {}

  ngOnInit(): void {
    
    this.ug = localStorage.getItem('ug') + ' - ' + localStorage.getItem('uf');
    this.brasao = localStorage.getItem('brasaoImg');
  }
}
